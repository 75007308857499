/* spot layout */
.banner_link {
  display: block;
  width: 352px;
  height: 220px;
  overflow: hidden;
} /*여기가 3개배너 위치 정하는곳 */
.spot_section {
  position: relative;
  width: 1400px;
  height: 262;
  margin: 0 auto;
  padding: 0 0 0px;
  outline: 0;
}
.spot_row {
  margin-top: 0px;
  *zoom: 1;
  padding-top: 40px;
}
.spot_row:first-child {
  margin-top: 0;
  padding: 0px 12px;
}
.spot_col {
  float: left;
  margin-left: 11px;
}
.spot_col:first-child {
  margin-left: 0;
}

/* rolling */
.rolling {
  position: relative;
}
.rolling_box {
  overflow: visible;
  position: relative;
  width: auto;
  height: 550px;
}
.rolling_item {
  display: inline-block;
  position: relative;
  width: 262px;
  height: 550px;
  *display: inline;
  *zoom: 1;
}

.rolling_img {
  position:relative;
  font-size: 1px;
  vertical-align: bottom;
  margin-top: -6.7rem;
  width: 100%;
}

/* member */
.member {
  position: relative;
  display: block;
  width: 280px;
  height: 220px;
  margin-left: 28px;
  background: #fff;
  border: 1px solid #e2e2e2;
  font-family: ng;
}
.login_set {
  height: 30px;
  padding-left: 22px;
  line-height: 0;
}
.login_setSocial {
  position: absolute;
  height: 10px;
  width: 100px;
  margin: 23 0 0 107px;
}
.login_set .checkbox-applied {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 12px;
  height: 12px;
  border: 1px solid #e2e2e2;
  border-top-color: #c9cacb;
  vertical-align: middle;
  top: 35;
}

.login_set .checkbox-mark {
  display: none;
  position: absolute;
  top: 0;
  right: -1px;
  width: 12px;
  height: 9px;
  background-position: -124px -131px;
}

.login_set .checkbox-checked {
  display: block;
}

.login_set .chk_label {
  display: inline-block;
  position: relative;
  margin-left: 1px;
  *margin-left: 5px;
  padding-top: 35px;
  font-size: 11px;
  color: #888;
  line-height: 13px;
  vertical-align: top;
  cursor: pointer;
}

.login_form_item {
  width: 233px;
  height: 40px;
  padding: 0;
  border: 1px solid #dadddf;
  vertical-align: middle;
  margin-left: 22;
}

.login_form_item:first-child {
  border-bottom: 0;
}

.login_form_item .txt_inp {
  width: 213px;
  height: 20px;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 13px;
  color: #212121;
  line-height: 20px;
  vertical-align: top;
}

.login_btn {
  position: relative;
  right: 0;
  width: 235px;
  height: 45px;
  background: #333333;
  line-height: 45px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  margin-left: 22;
}

.login_help button {
  font-family: ng;
  font-size: 11px;
  letter-spacing: -1px;
  line-height: 16px;
  color: #000;
}

.mem_id_search {
  margin-left: 7px;
}
.mem_id_search,
.mem_pw_search,
.mem_join {
  float: left;
}
.mem_id_search button:hover {
  color: #15a3e1;
}
.mem_pw_search button:hover {
  color: #15a3e1;
}
.mem_join a:hover {
  color: #15a3e1;
}
.login_help_bar {
  float: left;
  width: 1px;
  height: 8px;
  margin: 4px 10px 0 10px;
  background: #dfdfdf;
  vertical-align: top;
}

/*아이디찾기 팝업 */
.Findid_popup {
  position: absolute;
  width: 440px;
  height: 358px;
  z-index: 1;
  margin: -152 0 0 -142;
  background-color: #fff;
  border: 5px solid #4e4e4e;
}

.Findid_titleBox {
  position: relative;
  display: block;
  width: 100%;
  height: 70px;
  background-color: #4e4e4e;
  font-size: 20px;
  color: #fff;
  text-align: center;
  line-height: 70px;
}

.Findid_Box {
  display: block;
  width: 100%;
  height: 189px;
  border-bottom: 1px solid #dbdbdb;
  background-color: #f9f9f9;
}
.Findid_Inner {
  width: 370px;
  height: auto;
  padding: 66px 0 0 92px;
}
.Findid_mobileimg {
  position: absolute;
  display: inline-block;
  width: 30px;
  height: 48px;
  margin: 5px 0 0 -40px;
}
.Findid_Title {
  font-size: 20px;
  font-weight: bold;
}
.Findid_Contents {
  width: 340px;
  font-size: 16px;
  font-weight: normal;
  color: #7b7b7b;
}
.oknoBox_button.Findid {
  padding-top: 22px;
}

/* 비밀번호 찾기 */
.Findid_Inner.pw {
  padding: 40px 0 0 92px;
}
.Findpw_passwardBox {
  position: relative;
  display: block;
  width: 350px;
  height: 35px;
  background-color: #f3f3f3;
  margin-left: -40px;
  margin-top: 0;
  margin-bottom: 20px;
}
.Findpw_inputBox {
  position: absolute;
  display: inline-block;
  width: 330px;
  height: 35px;
  font-size: 14px;
  padding: 8px 0 0 12px;
  z-index: 1;
}
.Findpw_inputBox input {
  color: #4e4e4e;
  width: 100%;
  font-size: 14px;
}

/* 로그인 후 */
.memberContents {
  width: 230px;
  height: auto;
  padding: 17 0 0 26;
}
.memberNameBox {
  width: 100%;
  height: 61px;
}
.memberChargeBox {
  width: 100%;
  height: 51px;
  font-size: 20px;
  background-color: #f9f9f9;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.memberLogoutButton {
  position: absolute;
  display: inline-block;
  height: 23px;
  width: 66px;
  color: #4e4e4e;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  margin: -36 0 0 186;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.SMmoney_chargebutton {
  position: absolute;
  display: inline-block;
  color: #ffffff;
  background-color: #c10000;
  font-size: 12px;
  margin: -28 0 0 214;
  padding: 3 9 4;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.memberLastGame {
  position: absolute;
  width: 115px;
  height: 65px;
  margin: -37 0 0 139;
}
.memberLastGame img {
  width: 100%;
  height: auto;
}

/* Center_event_section */
.spot_event {
  margin-top: 80px;
}
.event_link {
  height: 210px;
}
.event_img {
  width: 1920px;
  height: auto;
}

/* tab menu */
.tab_box {
  font-size: 0;
  text-align: center;
}
.game_section {
  position: relative;
  height: auto;
  margin-top: 32px;
  padding-bottom: 80px;
} /*1112px게임 넣는곳 큰박스 */

.games_opt_list {
  padding: 40px 0px 10px 0px;
  /* margin: auto; */
}

.game_Menu_title {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #004279;
  line-height: normal;
  text-align: left;
  padding: 10px 0px 0px 0px;
 

}

.game_title_below_icon{
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  width: 150px;
}

.tabcontent {
  display: grid;
  grid-template-columns: repeat(4, 0fr);
  gap: 40px;
  /* gap: 20px 22px; */
  /* margin-top: 60px; */
  /* position: absolute; */
  /* width: 60%; */
  /* height: 60%; */
  /* display: table; */
  /* background-color: white; */
  /* top: 20px;
  right: -5%; */
  /* margin: auto; */
  padding: 10px 0px 10px 0px;
  margin-left: 25px;
} /* 탭컨텐츠 요기로~ */

.tabcontent a {
  /* width: 155px; */
  margin: 0px;
}

.tabcontent:nth-child(n + 3) {
  /* height: 473px; */
}
.girl_and_games {
  top: 100px;
  /*width: 70%;*/
  height: 60vh;
  background-color: white;
  position: relative;
  margin: 0 auto;
}

.online_area {
  position: relative;
  width: 1400px;
  height: 262;
  margin: 0 auto;
  padding: 0 0 0px;
  outline: 0;
} /* 게임넣는곳 배경 */
.online_box {
  position: relative;
  width: 1400px;
  height: 262;
  margin: 0 auto;
  padding: 0 0 0px;
  outline: 0;
} /* 게임들 padding*/

/* card list */
.card_item {
  /* float: left;
  position: relative; */
  /* width: 190px;
  height: 80px; */
  display: flex;
  align-items: center;
  gap: 20px;
}

.cards_container_jay {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.card_img_box {
  display: block;
  position: relative;
  z-index: 10;
  width: 320px;
  height: 280px;
  overflow: hidden;
}
.card_tit {
  overflow: hidden;
  display: block;
  margin: 30px 0px 10px 0px;
  font-size: 20px;
  font-weight: bold;
  color: #212121;
  line-height: 20px;
  text-overflow: ellipsis;
}
.card_txt {
  display: block;
  margin: 7px 0 0 0px;
  font-size: 15px;
  color: #8d8d8d;
  line-height: 20px;
}
.card_category {
  display: block;
  margin: 24px 0 0 0px;
  font-size: 15px;
  color: #15a3e1;
  line-height: 55px;
  border-top: 1px solid #eeeeee;
}
.col_type1 {
  /* background-color: #ffffff;
  margin-right: 0px;
  margin-left: 20px;
  margin-bottom: 40px;
  margin-top: 20px; */
}
.col_type1:nth-child(4) {
  margin-right: 5px;
} /*margin-right 5px*/
.col_type1:nth-child(12) {
  margin-right: 0px;
}
.card_item .card_img_box img,
.card_round_item .card_img_box img {
  width: 320;
  height: auto;
  -webkit-transition: transform 0.2s;
  -moz-transition: transform 0.2s;
  -o-transition: transform 0.2s;
  -ms-transition: transform 0.2s;
  transition: transform 0.2s;
}
.card_item a:hover .card_img_box img,
.card_round_item a:hover .card_img_box img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

/* footer TOP */
.info_Topsection {
  width: 100%;
   height: 79px;
  background-color: #ffffff;
}

.info_Topsection_innerBox {
  position: relative;
  display: flex;
  width: 1400px;
  margin: 0 auto;


}
.info_Topsection_inner1 {
  display: flex;
  width: 50%;
  height: 80px;
  background-color: #ffffff;
  overflow: hidden;

  gap: 129px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.info_Topsection_inner1 a:hover {
  color: #15a3e1;
}

.info_Topsection_inner1_txt {

}
.info_Topsection_inner1_txt2 {

}

.info_Topsection_inner1_img {
  position: absolute;
  margin: 0 -36px;
}
.info_Topsection_inner1_img2 {
  position: absolute;
  margin: 41px -36px;
}
.info_Topsection_inner2 {
  display: inline-block;
  width: 49.8%;
  height: 80px;
  background-color: #2c2c31;
  overflow: hidden;
  text-align: left;
}
.info_Topsection_inner2Box {
  display: inline-block;
  position: absolute;
  width: 3600px;
  height: 80px;
  background-color: #2c2c31;
  /* top: 0;
  left: 760;
  bottom: 0;
  right: 0;
  margin: 0 auto; */
}
.info_Topsection_inner2_txt {
  position: absolute;
  width: 200px;
  height: 30px;
  margin: 21px 50px;
  color: #ffffff;
}

.info_Topsection_inner2_txt2 {
  position: absolute;
  width: 500px;
  height: 30px;
  margin: 27px 280px;
  color: #8f8f8f;
}

.info_Topsection_inner2_txt2_hover:hover {
  color: #ffffff;
}

.info_Topsection_inner2_txt2 a {
  color: #8f8f8f;
  padding-right: 45px;
}

.info_Topsection_inner2_txt2 a:hover {
  /* color: #4e4e4e; */
  color: #15a3e1;
}

/* footer - 정보 */
.info_section {
  border-top: 1px solid #eeeff0;
  background: #2c2c31;
  /* height: 186px; */
  text-align: center;
  display: flex;
  justify-content: center;
} /*footer 전채 */

.info_section .inner {
  /* position: relative;
  padding: 38px 0 50px;
  left: 1%; */
}
.info_section a:hover {
  color: #15a3e1;
}
.logo_SM {
  background-position: 0 -0px;
  cursor: pointer;
}

/* 개인정보취급방침 */
.info_txt {
  color: #b4b4b4;
  line-height: 16px;
  white-space: nowrap;
}

.info_txt_hover:hover {
  color: #ffffff;
}

.info_txt_bar {
  display: inline-block;
  width: 1px;
  height: 8px;
  background: #5d5e5e;
  vertical-align: middle;
  margin: -2px 10px 0;
}
.copyright {
  color: #b4b4b4;
  font-family: 'arial', 'verdana', dotum, sans-serif;
  margin-top: 17px;
  font-size: 12px;
  line-height: 1.8;
}
.copyright_link {
  color: #b4b4b4;
}

.make_li_center {
  display: inline;
}

/* 롤링 설정부분 */
.swiper-slide {
  text-align: center;
  display: flex;
  /* 내용을 중앙정렬 하기위해 flex 사용 */
  align-items: center;
  /* 위아래 기준 중앙정렬 */
  justify-content: center;
  /* 좌우 기준 중앙정렬 */
}
.swiper1 .swiper-pagination {
  position: absolute;
  top: 580px;
  right: 10px;
}
.swiper1 .swiper-pagination-bullet {
  border-radius: 0;
  width: 63;
  height: 6px;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.swiper1 .swiper-pagination-bullet-active {
  color: #fff;
  background: #15a3e1;
}

.carosel_jay {
  width: 500px;
  height: 500px;
  display: flex;
  overflow: hidden;
}

.main_div_carosel {
  width: 100%;
  position: relative;
  height: 400px;
  z-index: 99;
  gap: 20px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  background: #FFFFFF;
}

.main_div_carosel2 {
  position: relative;
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 0.5rem;
}

.main_div_images {
  display: flex;
  width: 220px;
  padding: 1px 20px;
  z-index: 5;
  justify-content: center;
  position:absolute;
  top: 118px;
}

.main_div_images:hover img{
  transform: scale(1.20);
  transition: 0.08s;
}

.BoxClass:hover img {
  transform: scale(1.20);
  transition: 0.08s;
}


.prev {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 2rem;
  text-shadow: 0 0 20px red;
  text-align: center;
  color: white;
  text-decoration: none;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  transition: all 150ms ease;
}
.next {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 2rem;
  text-shadow: 0 0 20px red;
  text-align: center;
  color: white;
  text-decoration: none;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  transition: all 150ms ease;
}

.green_banner_div {
  position: relative;
  display: flex;
  /* margin-top: 9%; */
  /* margin-bottom: -4%; */
  background: white;
  margin: auto;
}

.game_text {
  font-family: Noto Sans KR;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;

  /* text-align: left;
  position: absolute;
  top: 30%;
  left: 45%;
  width: 130px;
  height: 30px; */
  color: #222222;
  white-space: nowrap;
}

.swiper1 .swiper-slide {
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  /* 내용을 중앙정렬 하기위해 flex 사용 */
  align-items: center;
  /* 위아래 기준 중앙정렬 */
  justify-content: center;
  /* 좌우 기준 중앙정렬 */
  overflow: hidden;
}
.swiper2 .swiper-slide {
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  /* 내용을 중앙정렬 하기위해 flex 사용 */
  align-items: center;
  /* 위아래 기준 중앙정렬 */
  justify-content: center;
  /* 좌우 기준 중앙정렬 */
}

/* 탭부분 */
.tab {
  position: relative;
  height: 150px; /* 임의로 준 크기.*/
  background: white;
}

.main_game_tab_txt_div {
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  padding-top: 2rem
}

.main_game_tab_text {
  color: #004279;
  font-size: 21px;
  font-weight: 700;
  line-height: 1.5;
  margin-right: 67.3rem;
}

.tabtext {
  color: #004279;
  font-size: 42px;
  font-weight: 500;
  line-height: 1.5;
}

.tablinks {
  border: none;
  outline: none;
  font-size: 20px;
  text-align: left;
}

.tablinks.active {
  color: #15a3e1;
  border-bottom: 3px solid #15a3e1;
}


/* IE10+ */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* Enter your style code */
  /* rolling */

  .rolling {
    position: relative;
  }
  .rolling_box {
    overflow: visible;
    position: relative;
    width: auto;
    height: 550px;
  }
  .rolling_item {
    display: inline-block;
    position: relative;
    width: 262px;
    height: 550px;
    *display: inline;
    *zoom: 1;
  }

  .rolling_img {
    width: auto;
    height: 550px;
    font-size: 1px;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
  }

  /*게임 설명란 */
  .section_tit_box {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 111px;
    margin: 0% auto;
    background-color: #f5f5f5;
    border-bottom: 1px solid #bcbcbc;
  }
  .section_tit_box_inner {
    position: relative;
    width: 1400px;
    height: 37px;
    top: 69;
    left: 615;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }
  .section_tit_box_inner .section_tit {
    font-size: 26px;
  }
  .section_tit_underLbox {
    position: absolute;
    display: inline-block;
    width: 1400px;
    height: 20px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    zoom: 1;
    *display: inline;
  }
  .section_tit_box_underL {
    position: absolute;
    display: inline-block;
    height: 1px;
    border-bottom: 4px solid #15a3e1;
  }
  .section_tit_box_underL2 {
    position: absolute;
    display: inline-block;
    height: 1px;
    border-bottom: 4px solid #4e4e4e;
  }

  .box_underL {
    position: relative;
    display: inline-block;
    height: 41px;
    color: #4e4e4e;
    font-size: 25;
    border-bottom: 4px solid #15a3e1;
  }
  .box_underR {
    position: relative;
    display: inline-block;
    height: 41px;
    color: #4e4e4e;
    font-size: 25;
    border-bottom: 4px solid #4e4e4e;
    margin-left: -5px;
  }

  .login_btn {
    position: relative;
    right: 0;
    width: 235px;
    height: 45px;
    background: #333333;
    line-height: 50px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    margin-left: 22;
  }

  .info_Topsection_inner1 {
    display: inline-block;
    width: 49.5%;
    height: 80px;
    background-color: #ffffff;
    overflow: hidden;
    text-align: right;
  }
  .info_Topsection_inner1_txt {
    position: absolute;
    color: #2c2c31;
  }
  .info_Topsection_inner1_txt2 {
    position: absolute;
    width: 400px;
    height: 30px;
    margin: 35px 0 0 120px;
    color: #2c2c31;
  }
  .info_Topsection_inner1_img {
    position: absolute;
    margin: 0 0 0 656px;
  }
  .info_Topsection_inner1_img2 {
    position: absolute;
    margin: 41px 0 0 656px;
  }

  /* Member */
  .login_setSocial {
    position: absolute;
    height: 10px;
    width: 100px;
    margin: 23px 0 0 103px;
  }
}

.footer {
  /* position: relative;
  top: 17vh; */
}
.blue_girl {
  width: 551px;
  height: 638px;
  position: relative;
  left: 5%;
  top: -15%;
}
.center_content {
  height: 756px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center_container{
  width: 1280px;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.center_container_section{
  width:1600px;
  margin: 0 auto;
}

.contents {
  height: auto;
  background: white;
  display: flex;
  padding: 5rem 0 0 0;
  justify-content: space-evenly;
  align-items: normal;
}

.upcoming_game_contents {
  height: auto;
  background-color: #ECECEC;
  display: flex;
  justify-content: space-evenly;
  align-items: normal;
}

.underline_image {
  height: 1%;
  width: 18%;
}

.footer_logo {
  position: relative;
  width: 148px;
  height: 103px;
  left: -50%;
  display: flex;
}

.swiper-slide {
  cursor: pointer;
}

.swiper-slide video {
  height: 40rem;
  pointer-events: none;
}

.swiper-button-next:hover {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  transform: scale(1.1);
}

.swiper-button-prev:hover {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  transform: scale(1.1);
}

.swiper-button-next,
.swiper-button-prev {
  transition: all 0.3s ease;
}


.see_more {
  color: #004279;
}


.see_more_txt {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.563rem;
  word-wrap: break-word
}


.see_more_btn {
    display: flex;
    justify-content: space-between;
    gap: 0.8rem;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.563rem;
    word-wrap: break-word;
}

.bttn:hover .see_more_btn_arrow{
  background-image: url("../images/main_img/GroupWhite.svg");
}


.see_more_btn_arrow {
    width: 1.563rem;
    height: 1.563rem;
    margin-left: 0.4rem;
    background-image: url("../images/main_img/Group.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}


.btn {
    width: 6rem;
    height: 1rem;
    padding-left: 25px;
    padding-right: 25px;
    /*background: linear-gradient(225deg, #F76680 0%, #57007B 100%);*/
    background: none;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    gap: 10rem;
    display: inline-flex;

    border: none;
	outline: inherit;
}

.bttn {
    font-size: 16px;
    line-height: 160%;
}

.bttn.bttn-primary {
    align-items: center;
    border: 1px solid #004279;
    border-radius: 5px;
    color: #004279;
    display: flex;
    gap: 16px;
    justify-content: center;
    padding: 12px 20px;
    position: relative;
    transition: all .3s ease;
    z-index: 1;
}

.bttn.bttn-primary:after {
    background: #004279;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .3s ease;
    width: 0;
    z-index: -1;
    border-radius: 5px;
}

.bttn.bttn-primary:hover{
    border-radius: 6px;
    /*border: 1px solid transparent;*/
    color:#fff;
}

.bttn.bttn-primary:hover:after{
    border-radius: 5px;
    left:0;
    width:100%;
}