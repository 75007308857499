
.guide_wrap {
    border: 5px solid #004701;
    border-left: none;
    width: 880px;
    height: 620px;
    background-color: #ffffff;
    border-left: none;
}

.guide_wrap_baduki {
    border: 5px solid #143798;
    border-left: none;
}

.guide_wrap_poker {
    border: 5px solid #006d83;
    border-left: none;
}


.guide_left_wrap {
    position: relative;
    width: 140px;
    height: 100%;
}

.guide_right_wrap {
    position: absolute;
    width: 740px;
    height: 620px;
    background-color: #f4e7d7;
    margin: -620px 0 0 140px;
    overflow: auto;
    line-height: 2;
}

.guide_right_content {
    color: black;
    margin: 40px 40px auto;
    font-size: 13px;
}

.guide_right_content img {
    padding-bottom: 10px;
}

.guide_right_txt_content {
    width: auto;
    height: auto;
    padding: 5px 0 30px 0;
}

.guide_right_bullet_txt {
    width: auto;
    height: auto;
    padding: 5px 0 30px 0;
    line-height: 2;
}

.making_room li{
    /*margin-bottom: 10px;*/
}

.guide_left_logo_box {
    position: relative;
    display: inline-block;
    width: 140px;
    height: 100px;
    line-height: 22px;
    text-align: center;
    margin: 25px 0 0 0;
}

.guide_left_menu_button {
    position: relative;
    display: block;
    width: 140px;
    height: 35px;
    font-size: 16px;
    line-height: 35px;
}

.guide_left_menu_button_inner {
    width: 90px;
    height: 100%;
    margin: 0 auto;
    cursor: pointer;
}

.guide_left_button_img_open_tab {
    position: absolute;
    width: 15px;
    height: 15px;
    margin: -25px 0 0 92px;
    background-image: url("../images/Icon-sub.png") !important;
}

.guide_left_button_img_close_tab {
    position: absolute;
    width: 15px;
    height: 15px;
    margin: -25px 0 0 92px;
    background-image: url("../images/Icon-plus2.png");
}

.guide_left_menu_inner_button {
    display: block;
    width: 140px;
    height: auto;
    font-size: 12px;
    color: #ffffff;
}

.guide_left_menu_inner {
    width: 90px;
    height: 100%;
    margin: 0 0 0 40px;
    line-height: 2.8;
}

.guide_left_menu_inner_tab{
    display: block;
    position: absolute;
}

.guide_left_menu_inner_tab div{
    cursor: pointer;
}

.guide_inner_tab {
    display: flex;
    color: #559856;
    font-size: 13px;
}


.guide_inner_tab_baduki {
    display: flex;
    color: #4e9dff;
    font-size: 13px;
}


.guide_inner_tab_poker {
    display: flex;
    color: #00bfbd;
    font-size: 13px;
}


.active {
    color: #ffffff;
}

.guide_left_arrow {
    margin: 13px 0 0 5px;
    width: 9px;
    height: 11px;
    background-image: url("../images/Icon-active.png");
}

.guide_left_menu_under_line {
    display: block;
    width: 100%;
    height: 1px
}

.guide_right_content li:before {
    top: 5.5px
}

.guide_right_content_ol_img img {
    margin-left: -30px;
}

.guide_bullet_img {
    width: 5px;
    height: 5px;
    position: relative;
    display: inline-block;
    background-repeat: no-repeat;
    padding-left: 7px;
    top: -2px;
    background-image: url('../images/daulgo_guide_img/Icon-bullet.png');
}

.guide_bullet_img_top {
    top: 10.5px;
}

.guide_bullet_txt {
    font-weight: bold;
    font-size: 14px;
    color: #603513;
}

.guide_bullet_txt_img {
    width: 9px;
    height: 9px;
    position: relative;
    display: inline-block;
    background-repeat: no-repeat;
    padding-left: 7px;
    top: -1px;
    background-image: url('../images/daulgo_guide_img/Icon-bullet2.png');
}

.guide_additional_rules {
    line-height: 22px;
    padding-left: 0;
    list-style-type: none !important;
    counter-reset: my-counter;
    margin-left: 20px !important;
}

.guide_additional_rules li::before {
    counter-increment: unset;
    content: '-';
    width: 14px;
    height: 14px;
    line-height: 13px;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: unset;
    border: unset;
    margin-right: 0.625rem;
    vertical-align: middle;
    position: absolute;
    left: -1.44rem;
    top: 4.5px;
    align-item: center;
    font-size: 12px;
}

.guide_grade_box {
    display: block;
    width: 100%;
    height: 50px;
    font-size: 14px;
    background-color: #fff;
    border-bottom: 1px solid #ebd9c5;
    line-height: 50px;
}


.guide_grade_box_poker {
    display: flex;
    width: 100%;
    height: 70px;
    font-size: 14px;
    background-color: #fff;
    border-bottom: 1px solid #ebd9c5;
    line-height: 50px;
}


.guide_grade_inner_box {
    display: inline-block;
    width: 195px;
    height: 100%;
    font-weight: bold;
    color: #4e4e4e;
    background-color: #faf3eb;
    text-align: center;
    border-right: 1px solid #ebd9c5;
}


.guide_grade_inner_box_poker {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 195px;
    height: 100%;
    font-weight: bold;
    color: #4e4e4e;
    background-color: #faf3eb;
    text-align: center;
    border-right: 1px solid #ebd9c5;
}

.guide_grade_inner_box_2 {
    display: inline-block;
    width: 400px;
    height: 100%;
    color: #7b7b7b;
    padding-left: 30px;
}

.guide_grade_inner_box_poker_2 {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 400px;
    height: 100%;
    color: #7b7b7b;
    padding-left: 30px;
    line-height: 2;
}

.guide_menu_title {
    color: #559856;
}

.guide_baduki_how_to_bet{
    background-color: #DCDCDC;
    position: relative;
    z-index: 200;
    width: 679px;
    margin-left: -21px;
    height: 228px;
    border-radius: 8px;
}

.guide_bullet_txt_container {
    display: flex;
    line-height: 2;
}