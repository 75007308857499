@media only screen and (orientation: portrait) {
    .in_game_popup_background {
        display: none;
    }
}

.in_game_popup_background {
    width: 98vw;
    height: 99.7vh;
    background-color: #FFF8EF;
    border-radius: 0.6vw 0.6vw 0.6vw 0.6vw;
    padding: auto;
    align-self: auto;
    margin: 0 1.0000vw;
    overflow-y: hidden;
    font-family: 'Noto Sans KR', 'ng', 'NanumGothic', sans-serif;
    font-size: 0.9375vw;
    color: #333;
    line-height: 0.95;
    overflow: scroll;
}

.in_game_day_limit_tab {

}

.in_game_day_limit_tab_img {
    height: 6vw;
    background-image: url("../images/dualgo_ingame_img/day_limit.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.day_limit_content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.day_limit_content_inner {
    align-items: center;
    margin-top: 3vw;
}

.day_limit_instruction {
    display: flex;
    align-items: center;
    list-style: none;
    color: #414141;
    font-weight: 700;
    font-size: 1.1vw;
    line-height: 2;
}

.day_limit_instruction::before {
    content: '';
    background-image: url("../images/dualgo_ingame_img/Bullet_Arrows.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: inline-block;
    width: 1vw;
    height: 1vw;
    margin-right: 0.521vw;
}

.day_limit_buttons {
    height: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vw;
}

.day_limit_buttons_button_family {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.button_space {
    margin: 0 -1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    padding: 0;
    cursor: pointer;
    flex-wrap: nowrap;
    align-content: space-around;
    flex-direction: row;
}

.day_limit_left_button {
    height: 7.481vw;
    width: 17vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../images/dualgo_ingame_img/Left_Button.svg");
    padding-right: 0.625vw;
}

.day_limit_left_button:hover {
    background-image: url("../images/dualgo_ingame_img/Left_Button_Hover.svg");
}


.day_limit_right_button {
    height: 7.481vw;
    width: 17vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../images/dualgo_ingame_img/Right_Button.svg");
    padding-left: 0.625vw;
}

.day_limit_right_button:hover {
    background-image: url("../images/dualgo_ingame_img/Right_Button_Hover.svg");
}

.day_limit_center_button {
    height: 6.481vw;
    width: 22.4vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../images/dualgo_ingame_img/C_Button.png');
}

.day_limit_button_text {
    font-size: 1.4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.25vw;
}


.day_limit_submit_btn_box {
    display: block;
    margin-top: 2vw;
    margin-bottom: 3.5vw;
    text-align: center;
}


.in_game_submit_btn {
    width: 16.23vw;
    height: 4.22vw;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    cursor: pointer;
    color: #ffffff;
    position: relative;
    background-image: url("../images/dualgo_ingame_img/Setting_Button.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    font-size: 1.6vw;
    font-weight: normal;
}


.in_game_submit_btn:hover {
    color: #E4831B;
    background-image: url("../images/dualgo_ingame_img/Setting_Button_Hover.svg");
}


.in_game_submit_btn span {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.08vw;
}

.in_game_popup_dim {
    position: absolute;
    width: 100%;
    height: 101%;
    background-color: #000000;
    opacity: 0.5;
    z-index: 3;
}

.day_limit_popup_center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: absolute;
}

.message_popup_background {
    position: absolute;
    width: 36.2031vw;
    height: 16.0156vw;
    background-color: #ffffff;
    opacity: 1;
    border-radius: 1.5625vw;
    border: 0.2344vw solid #d57b13;
    z-index: 4;
    display: flex;
    justify-content: center;
    text-align: center;
}

.popup_img_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup_ok_img {
    position: absolute;
    height: 2.271vw;
    width: 2.271vw;
    top: 2.083vw;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    align-self: flex-start;

    background-image: url("../images/dualgo_ingame_img/Right_But.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.popup_no_img {
    position: absolute;
    height: 2.271vw;
    width: 2.271vw;
    top: 2.083vw;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    align-self: flex-start;

    background-image: url("../images/dualgo_ingame_img/Close_Bu.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.popup_ok_button {
    position: absolute;
    width: 11.406vw;
    height: 2.604vw;
    cursor: pointer;
    bottom: 2.083vw;
    color: #FFF;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 700;
}

.popup_message_text {
    font-weight: 700;
    font-size: 1.2vw;
    color: #000000;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-top: 7vw;
}

.shake {
    animation-duration: 0.9s;
    animation-delay: 0.2s;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.day_limit_remaining_limit {
    display: flex;
    justify-content: center;
    align-items: center;
}

.day_limit_last_limit {
    font-size: 1.2vw;
    color: #414141;
    text-align: right;
}

.day_limit_last_limit_2 {
    font-size: 1.2vw;
    color: #414141;
    padding-left: 0.7vw;
}

.day_limit_bottom_txt {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    font-size: 1.3vw;
    font-weight: 700;
    width: 98vw;
    bottom: 2vw;
}

.cash_box_currency_switch {
    margin-top: 3vw;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.cash_box_contents_inner_box {
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
}

.cash_box_contents_tab_inner_box {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.currency_option {
    width: 33vw;
    height: 4vw;
    border: 0.1vw solid #ad5f00;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}

.currency_option:first-child {
    border-radius: 0.7vw 0 0 0.7vw;
    border-right: none;
}

.currency_option:last-child {
    border-radius: 0 0.7vw 0.7vw 0;
}

.currency_option_active {
    background-color: #e4831b
}

.currency_option_not_active:hover {
  background-color: #f9e3c9;
}

.cash_box_tab {
    border-radius: 8px;
    cursor: pointer;
}

.cash_box_tab {
    display: inline-block;
    width: 30vw;
    height: 4vw;
    color: #e4831b;
    font-size: 1.6vw;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    line-height: 4vw;
    border: 0.1vw solid #ad5f00;
    margin-top: 3.657vw;
}

.cash_box_tab.active {
    background-color: #e4831b;
    color: white;
}

.cash_box_tab.inactive:hover {
  background-color: #f9e3c9;
}

.cash_box_tab_content {
    margin-top: 2vw;
}

.cash_box_current_tab_div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cash_box_label_box {
    border-radius: 0.417vw;
    border: 0.1vw solid #ffc17e;
    width: 14.194vw;
    height: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4vw;
    font-weight: 700;
}

.cash_box_money {
    border: 0.1vw solid #ffc17e;
    padding: 0 0.781vw;
    width: 21.732vw;
    height: 4vw;
    background-color: #FFD7AB;
    border-radius: 0.417vw;
    font-size: 1.4vw;
    color: #e4831b;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-left: 0.9vw;
}

.cash_box_money_box_div {
    display: flex;
    margin-top: 0.9vw;
}

.cash_box_right_input {
    padding: 0 0.781vw;
    width: 21.732vw;
    height: 4vw;
    border: 0.1vw solid #ffc17e;
    color: #e4831b;
    font-size: 1.4vw;
    font-weight: 700;
    margin-left: 0.9vw;
    text-align: right;
    line-height: 2.5;
    border-radius: 0.417vw;
    display: flex;
    justify-content: right;
    align-items: center;
}

.cash_box_input_money {
    width: 20.345vw;
    height: 4vw;
    font-size: 1.4vw;
    font-weight: 700;
    color: rgb(228, 131, 27);
    text-align: right;
    margin-left: -5vw;
}

.in_game_popup_btn {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 2vw;
    width: 30vw;
    height: 3.646vw;
    background-image: url('../images/dualgo_ingame_img/CashBox_Button2.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    color: white;
    align-items: center;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.in_game_popup_btn:hover {
    color: rgb(228, 131, 27);
    background-image: url("../images/dualgo_ingame_img/CashBox_Button2_Hover.svg");
}

.in_game_popup_btn_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.in_game_popup_btn_title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.4vw;
}

.cash_box_pay_list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative
}

.cash_box_history_container {
    display: flex;
    flex-direction: column;
}

.cash_box_history_item {
    display: flex;
}

.cash_box_history_item_title {
    border-radius: 0.417vw;
    border: 0.1vw solid #ffc17e;
    width: 10.345vw;
    height: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4vw;
    font-weight: 700;
    color: #414141;
    margin-top: 0.9vw;

}

.cash_box_history_item_content {
    border: 0.1vw solid #ffc17e;
    padding: 0 1vw;
    width: 20vw;
    height: 4vw;
    background-color: #FFD7AB;
    border-radius: 0.417vw;
    font-size: 1.4vw;
    font-weight: 700;
    color: #e4831b;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 0.9vw;
    margin-left: 0.9vw;
}

.cash_box_prev_next_btn_div {
    display: flex;
    width: 20vw;
    margin-top: 0.9vw;
    margin-left: 0.9vw;
}

.cash_box_prev_next_buttons {
    border-radius: 0.417vw;
    border: 0.1vw solid #ffc17e;
    width: 10.5vw;
    height: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4vw;
    font-weight: 700;
    color: #414141;
    cursor: pointer;
    margin-top: 0.9vw;
    margin-left: 0.9vw;
}

.cash_box_prev_next_buttons:hover {
    background-color: #f9e3c9;
}

.cash_box_right_contents {
    display: flex;
    gap: 2vw;
    margin-top: 2vw;
}

.cash_box_arrow_icon_left {
    width: 1.604vw;
    height: 1.604vw;
    padding-right: 0.5vw;
}

.cash_box_arrow_icon_right {
    width: 1.604vw;
    height: 1.604vw;
    padding-left: 0.5vw;
}

.free_charge_container_div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3vw;
}


.free_charge_container {
    display: flex;
    justify-content: left;
    align-items: center;
    color: #e4831b;
    text-align: center;
    font-size: 1.4vw;
    font-weight: 700;
    line-height: 3vw;
}

.free_charge_container img {
    width: 1.4vw;
    height: 1.4vw;
}

/* wallet */
.wallet_content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.wallet_currency_option {
    width: 33vw;
    height: 4vw;
    border: 0.1vw solid #ad5f00;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.7vw
}

.wallet_avatars {
    display: grid;
    grid-template-columns: repeat(3, 0fr)
}

.wallet_avatars_card {
    display: block;
    position: relative;
    width: 24.539vw;
    height: 14.5vw;
    overflow: hidden;
    background-color: #fff0de;
    border-radius: 0.417vw;
    margin-right: 3.01vw;
    margin-top: 2vw;
}

.wallet_avatars_card_div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wallet_avatars_card_img {
    position: relative;
    height: 11.5vw;
    border-radius: 1.1719vw;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
}

.wallet_left_arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4vw;
    height: 6vw;
    margin-top: 2vw;

    background-image: url('../images/dualgo_ingame_img/left_arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
}


.wallet_left_arrow:hover {
    background-image: url('../images/dualgo_ingame_img/left_arrow_blank.svg');
}

.wallet_right_arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4vw;
    height: 6vw;
    margin-top: 2vw;

    background-image: url('../images/dualgo_ingame_img/right_arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
}

.wallet_right_arrow:hover {
    background-image: url('../images/dualgo_ingame_img/right_arrow_blank.svg');
}

.wallet_currency_box {
    margin-top: 2vw;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.wallet_currency_box_2 {
    width: 38vw;
    height: 4vw;
    border: 0.1vw solid #ad5f00;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.7vw;
    font-size: 1.8vw;
    background-color: #844401;
    stroke: #ad5f00;
    color: #ffffff;
}

.wallet_avatars_pagination {
    height: 1.2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1vw;
}

.wallet_pagination_inactive_dot {
    width: 1.2vw;
    height: 1.2vw;
    margin-right: 1vw;

    background-image: url('../images/dualgo_ingame_img/InactiveDot.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
}

.wallet_pagination_active_dot {
    width: 1.2vw;
    height: 1.2vw;
    margin-right: 1vw;

    background-image: url('../images/dualgo_ingame_img/ActiveDot.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
}

.wallet_avatar_bag_text {
    font-weight: 400;
    font-size: 1.7vw;
    color: #e4831b;
    text-align: center;
}

.wallet_avatar_money_txt {
    height: 1.5vw;
    font-size: 1.5vw;
    text-align: center;
    color: #000;
    font-weight: 700;
    margin-top: 1vw;
}

.wallet_avatar_select_button {
    width: 8.581vw;
    height: 3vw;
    cursor: pointer;
    color: #ffffff;
    background-color: #e4831b;
    border-radius: 0.417vw;
    border: 0.1vw solid #E4831B;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wallet_avatar_select_button:hover{
    color: #E4831B;
    background-color: #f9e3c9;
}


.wallet_avatar_profile_selected {
    width: 8.581vw;
    height: 3vw;
    cursor: pointer;
    background-color: #FFF0DE;
    border-radius: 0.417vw;
    border: 0.1vw solid #E4831B;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wallet_avatar_select_button_txt {
    width: 100%;
    height: 100%;
    font-weight: 400;
    font-size: 1.5vw;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -0.1vh;
}

.wallet_card_content {
    align-items: center;
    width: 11.5vw;
    height: 11.5vw;
}

.wallet_avatar_select_btn_div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4.3vw;
}

.wallet_avatar_count_box {
    width: 2.04vw;
    height: 2.04vw;
    text-align: center;
    background-color: #ffcccc;
    border-radius: 520.781vw;
    justify-content: center;
    align-items: center;
}

.wallet_avatar_count_box_txt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: inherit;
    font-weight: 500;
    font-size: 1.1vw;
    color: #e41b1b;
    margin-top: -0.08vw;
}

.wallet_avatar_count {
    height: 13vw;
    width: 2.04vw;
    margin-right: 0.5vw;
}

.in_game_close_btn {
    position: absolute;
    width: 5vw;
    height: 5vw;
    margin-top: 1.2vw;
    margin-left: 91.7vw;

    background-image: url('../images/dualgo_ingame_img/close_button.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
}