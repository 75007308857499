@media only screen and (orientation: portrait) {
    .in_game_popup_background {
        display: none;
    }
}

.in_game_popup_background {
    width: 98vw;
    height: 99.7vh;
    background-color: #FFF8EF;
    border-radius: 0.6vw 0.6vw 0.6vw 0.6vw;
    padding: auto;
    align-self: auto;
    margin: 0 1.0000vw;
    overflow-y: hidden;
    font-family: 'Noto Sans KR', 'ng', 'NanumGothic', sans-serif;
    font-size: 0.9375vw;
    color: #333;
    line-height: 0.95;
}

.in_game_day_limit_tab {

}

.in_game_day_limit_tab_img {
    height: 6vw;
    background-image: url("../images/dualgo_ingame_img/day_limit.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.day_limit_content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.day_limit_content_inner {
    align-items: center;
    margin-top: 3vw;
}

.day_limit_instruction {
    display: flex;
    align-items: center;
    list-style: none;
    color: #414141;
    font-weight: 700;
    font-size: 1vw;
    line-height: 2;
}

.day_limit_instruction::before {
    content: '';
    background-image: url("../images/dualgo_ingame_img/Bullet_Arrows.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: inline-block;
    width: 1vw;
    height: 1vw;
    margin-right: 0.521vw;
}

.day_limit_buttons {
    height: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vw;
}

.day_limit_buttons_button_family {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.button_space {
    margin: 0 -1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    padding: 0;
    cursor: pointer;
    flex-wrap: nowrap;
    align-content: space-around;
    flex-direction: row;
}

.day_limit_left_button {
    height: 7.481vw;
    width: 17vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../images/dualgo_ingame_img/Left_Button.svg");
    padding-right: 0.625vw;
}

.day_limit_left_button:hover {
    background-image: url("../images/dualgo_ingame_img/Left_Button_Hover.svg");
}


.day_limit_right_button {
    height: 7.481vw;
    width: 17vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../images/dualgo_ingame_img/Right_Button.svg");
    padding-left: 0.625vw;
}

.day_limit_right_button:hover {
    background-image: url("../images/dualgo_ingame_img/Right_Button_Hover.svg");
}

.day_limit_center_button {
    height: 6.481vw;
    width: 22.4vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../images/dualgo_ingame_img/C_Button.png');
}

.day_limit_button_text {
    font-size: 1.4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.25vw;
}


.day_limit_submit_btn_box {
    display: block;
    margin-bottom: 2vw;
    text-align: center;
}


.in_game_submit_btn {
    width: 16.23vw;
    height: 4.22vw;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    cursor: pointer;
    color: #ffffff;
    position: relative;
    background-image: url("../images/dualgo_ingame_img/Setting_Button.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    font-size: 1.6vw;
    font-weight: normal;
}


.in_game_submit_btn:hover {
    color: #E4831B;
    background-image: url("../images/dualgo_ingame_img/Setting_Button_Hover.svg");
}


.in_game_submit_btn span {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.08vw;
}

.in_game_popup_dim {
    position: absolute;
    width: 100%;
    height: 101%;
    background-color: #000000;
    opacity: 0.5;
    z-index: 3;
}

.day_limit_popup_center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: absolute;
}

.message_popup_background {
    position: absolute;
    width: 36.2031vw;
    height: 16.0156vw;
    background-color: #ffffff;
    opacity: 1;
    border-radius: 1.5625vw;
    border: 0.2344vw solid #d57b13;
    z-index: 4;
    display: flex;
    justify-content: center;
    text-align: center;
}

.popup_img_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup_question_img {
    position: absolute;
    height: 2.271vw;
    width: 2.271vw;
    top: 2.083vw;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    align-self: flex-start;

    background-image: url("../images/dualgo_ingame_img/Questions_file.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.popup_ok_img {
    position: absolute;
    height: 2.271vw;
    width: 2.271vw;
    top: 2.083vw;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    align-self: flex-start;

    background-image: url("../images/dualgo_ingame_img/Right_But.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.popup_no_img {
    position: absolute;
    height: 2.271vw;
    width: 2.271vw;
    top: 2.083vw;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    align-self: flex-start;

    background-image: url("../images/dualgo_ingame_img/Close_Bu.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.popup_question_img {
    position: absolute;
    height: 2.271vw;
    width: 2.271vw;
    top: 2.083vw;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    align-self: flex-start;

    background-image: url("../images/dualgo_ingame_img/Questions_file.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}


.popup_ok_button {
    position: absolute;
    width: 11.406vw;
    height: 2.604vw;
    cursor: pointer;
    bottom: 2.083vw;
    color: #FFF;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 700;
}

.popup_message_text {
    font-weight: 700;
    font-size: 1.2vw;
    color: #000000;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-top: 7vw;
}

.shake {
    animation-duration: 0.9s;
    animation-delay: 0.2s;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.day_limit_remaining_limit {
    display: flex;
    justify-content: center;
    align-items: center;
}

.day_limit_last_limit {
    font-size: 1.2vw;
    color: #414141;
    text-align: right;
}

.day_limit_last_limit_2 {
    font-size: 1.2vw;
    color: #414141;
    padding-left: 0.7vw;
}

.day_limit_bottom_txt {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.3vw;
    font-weight: 700;
    width: 98vw;
}

.cash_box_currency_switch {
    margin-top: 3vw;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.cash_box_contents_inner_box {
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
}

/*@media only screen and (max-width: 1280px) {*/
/*    .day_limit_instruction{*/
/*        font-size: 0.5vw;*/
/*    }*/
/*}*/
