.TopGamesection3 {
  display: block;
  width: 100%;
  height: 17rem;
  background-position: center;
}

.TopGamesection_video3 {
  position: absolute;
  width: 100%;
  height: 17rem;
  margin: 0 auto;
  text-align: center;
  background-position: center;
  object-fit: cover;
}

.TopGamesection_inner3 {
  width: 1450px;
  height: 220px;
  margin: 0 auto;
}

.TopGame_StartButtonImg3 {
  position: absolute;
  margin: -15px 0 0 800px;
}

.game-banner-buttons {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.game-guide-btn {
    position: absolute;
    margin-top: 18.2rem;
    cursor: pointer;
    width: 124px;
    height: 43px;
}

.game-download-btn {
    margin-top: 3rem;
    cursor: pointer;
    width: 310px;
    height: 80px;
}

.GameCenterwarpMain {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
}

.GameCenterwarp {
    display: flex;
    width: 1400px;
    height: 955px;
    margin: 0 auto;
    gap: 35px
}

.GameCenterwarp_inner {
  width: 1000px;
  height: 850px;
  margin: 0;
  padding-top: 40px;
}

.tabcontentGame {
    position: relative;
    height: auto;
    margin: 0 auto;
    padding: 0 0 0px;
    outline: 0;
}

.section_tit_boxGame {
    position: absolute;
    top: -53px;
    left: 40px;
    bottom: 0;
    right: 0;
    height: 0px;
    margin: 0% auto;
    border: none;
    z-index: 2;
}

.tab {
    position: relative;
    text-align: right;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 63px;
    margin: 0% auto;
    border-bottom: 1px solid #bcbcbc;
}

.shop_section {
    position: relative;
    min-height: 794px;
    border: 1px solid #e2e2e2;
    background-color: #fff;
    /*top: 40px;*/
    margin-bottom: 80px;
}

.buybutton {
    border: 1px solid #dbdbdb;
    padding: 5px 15px;
    border-radius: 5px;
}

.col_type_game1 {
    margin: 30px 0 0 10px;
    margin-right: 30px;
    background-color: #ffffff;
}

.card_item_game2 {
    float: left;
    position: relative;
    width: 165px;
    height: 260px;
}



.col_type_game1:nth-child(5) {
    margin-right: 0;
}

.card_img_box_game {
    display: block;
    position: relative;
    z-index: 10;
    width: 98px;
    height: 145px;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
}

.grade_markimg_game {
  display: flex;
  position: relative;
  width: 230px;
  /* height: 82px; */
  /*overflow: hidden;*/
  /* margin: -102px 0 0 790px; */
}

.all_game_footer {
    margin-top: 5.5px;
}

.info_sectionGame {
    /*border-top: 1px solid #eeeff0;*/
    background: #333333;
    text-align: left;
}

.info_sectionGame .inner {
    display: flex;
    position: relative;
    padding: 38px 60px;
    justify-content: center;
    text-wrap: nowrap;
    margin: auto;
}

.gradeGame {
    width: 465px;
    height: 80px;
    background-color: #450102;
    /* margin: -100px 0 0 933px; */
    line-height: 25px;
    border: 1px solid #4e0a0b;
}

.grade_textBoxGame {
    position: relative;
    display: block;
    width: 100%;
    height: 33%;
    border-top: 1px solid #4e0a0b;
}

.grade_textBoxGame:nth-child(1) {
    border-top: none;
}

.grade_textinnerGame {
    position: relative;
    display: inline-block;
    width: 49%;
    height: 100%;
    color: #b4b4b4;
}

.grade_textinnerGame:nth-child(1) {
    border-right: 1px solid #4e0a0b;
}

.grade_innerleftGame {
    display: inline-block;
    width: 80px;
    height: 94%;
    padding-left: 10px;
}

.grade_innerrightGame {
    display: inline-block;
    position: relative;
    width: 135px;
    height: 100%;
}

.info_txt_bar_game {
    display: inline-block;
    width: 1px;
    height: 8px;
    background: #5d5e5e;
    vertical-align: middle;
    margin: -2px 10px 0;
}

.right_warp {
    display: inline-block;
    width: 360px;
    height: 794px;
    background-color: #ffffff;
    border: 1px solid #e2e2e2;
    margin-top: 40px;
}



.tablinks_game {
  margin-top: 12px;
  margin-left: 93px;
  margin-right: 55px;
  width: auto;
  height: 50px;
  border: none;
  outline: none;
  font-size: 16px;
}
.tablinks_game:nth-child(1) {
  margin-left: 40px;
  margin-right: 90px;
}
.tablinks_game:nth-child(2) {
  margin-left: 55px;
  margin-right: 30px;
}
.tablinks_game:nth-child(3) {
  margin-left: 100px;
  margin-right: 50px;
}
.tablinks_game:nth-child(4) {
  margin-left: 80px;
  margin-right: 65px;
}

.tablinks_game.active {
  font-size: 16px;
  border-bottom: 2px solid #4e4e4e;
  border-bottom-width: 20%;
}

.section_tit_box_inner_game {
    position: relative;
    width: 1000px;
    height: 37px;
    top: 75px;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    text-align: left;
}

.section_tit_box_inner_game .section_tit {
    font-size: 26px;
}


.popup_moneyBoxGame {
    position: relative;
    display: block;
    width: 100%;
    height: 65px;
    font-size: 16px;
    line-height: 3;
    color: black;
    text-align: left;
}

.popup_moneyBoxGame:nth-child(2) {
    border-bottom: 1px solid #e7e7e7;
}

.m_card_price {
    margin: 0.7813vw 0 0 0.0000vw;
    font-size: 2.3438vw;
    line-height: 4.6875vw;
}
