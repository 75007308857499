/* TOP */
.TopMysection {
  display: block;
  width: auto;
  height: auto;
  background-position: center;
  background-image: url('../images/servicecenter_img/servicecenter_P01.jpg');
  background-repeat: 'repeat-x';
}

.TopMysection_inner {
  height: 110px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

}

.TopMysection_title {
  position: absolute;
  display: inline-block;
  width: 1400px;
  height: 50px;
  font-size: 34px;
  font-weight: bold;
  color: #4e4e4e;
  text-align: center;
}

.TopMysection_tit {
  position: relative;
  display: inline-block;
  width: auto;
  height: 30px;
  font-size: 19px;
  font-weight: normal;
  color: #7b7b7b;
  padding: 12px 0 0 0;
  margin: 0 30px 0 30px;
}
.TopMysection_tit:hover {
  color: #15a3e1;
  border-bottom: 3px solid #15a3e1;
}

/* Layout */
.wrap {
  min-width: 1400px;
  background-repeat: no-repeat;
  background-position: 100%;
  background-color: #f5f5f5;
}
.Centerwarp {
  width: 600px;
  height: auto;
  min-height: 512px;
  margin: 0 auto;
  padding: 40px 0 0 0;
}
.Centerwarp_inner {
  width: 600px;
  height: auto;
  margin: 0;
}
.My_contentsBox {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  border-top: 2px solid #4e4e4e;
}
.My_contents {
  display: block;
  width: 100%;
  height: 76px;
  font-size: 16px;
  border-bottom: 1px solid #dbdbdb;
  line-height: 74px;
}

/* 회원가입 */
.membership_Box {
  display: block;
  width: 100%;
  height: auto;
  min-height: 250px;
  border: 1px solid #dbdbdb;
  border-top: 2px solid #4e4e4e;
  background-color: #f9f9f9;
}
.membership_TitleBox {
  position: relative;
  display: block;
  width: 100%;
  height: 74px;
  font-size: 16px;
  font-weight: bold;
  background-color: #ffffff;
  border-bottom: 1px solid #efefef;
}
.membership_Title {
  display: block;
  width: 100%;
  height: 56px;
  padding: 24px 0 0 30px;
}
.membership_contents {
  display: block;
  width: auto;
  height: 35px;
  color: #7b7b7b;
  font-weight: normal;
  font-size: 14px;
  margin: 15px 0 0 30px;
  line-height: 1.4;
}
.membership_uncheckimg {
  position: absolute;
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: 2px 0 0 -6px;
  cursor: pointer;
}
.membership_checkimg {
  position: absolute;
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: -1px 0 0 30px;
  cursor: pointer;
}
.agreement_Box {
  position: relative;
  display: block;
  width: 100%;
  height: 270px;
  background-color: #f9f9f9;
  padding-bottom: 30px;
  z-index: 1;
}

.agreement_contents {
  display: block;
  width: 538px;
  height: 268px;
  font-weight: normal;
  font-size: 14px;
  margin: 0 0 0 30px;
  line-height: 1.4;
  overflow: auto;
  background-color: #fff;
  border: 1px solid #dbdbdb;
}

.agreement_contents_Text {
  position: relative;
  width: auto;
  color: #7b7b7b;
  height: 100%;
  margin: 12px 15px 15px 15px;
}

.titleinterval {
  width: auto;
  height: auto;
  font-weight: bold;
  color: #4e4e4e;
  margin-bottom: 16px;
}
.contentsinterval {
  width: auto;
  height: 8px;
}

.oknoBox_button {
  display: block;
  width: 100%;
  height: 47px;
  padding-top: 40px;
  padding-bottom: 80px;
  text-align: center;
}

.okno_button {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #ffffff;
  background-color: #15a3e1;
  padding: 9px 46px 9px 14px;
  margin-top: 6px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.okno_button_img {
  position: absolute;
  background-image: url('../images/mypage_img/Icon-button.png');
  background-repeat: no-repeat;
  margin: -18px 0 0 53px;
}

/* 회원가입 입력창 */
.My_contentsBox {
  display: block;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  border-top: 2px solid #4e4e4e;
}
.My_contents {
  display: block;
  width: 100%;
  max-height: 100px;
  height: 76px;
  font-size: 16px;
  border-bottom: 1px solid #dbdbdb;
}
.My_contents_innerleftBox {
  display: inline-block;
  width: 216px;
  height: 76px;
  font-weight: bold;
  background-color: #f9f9f9;
  border-right: 1px solid #efefef;
  text-align: left;
}
.My_contents_innerrightBox {
  display: inline-block;
  width: 200px;
  height: auto;
  padding-left: 30px;
  font-family: 'Noto Sans KR Light';
}
.My_passwardBox {
  position: absolute;
  display: inline-block;
  width: 323px;
  height: 38px;
  background-color: #f3f3f3;
  margin-left: 30px;
  margin-top: 19px;
}
.passward_inputBox {
  position: absolute;
  display: inline-block;
  width: 256px;
  height: 37px;
  font-size: 14px;
  padding: 8px 0 0 12px;
  z-index: 1;
}
.passward_inputBox input {
  color: #4e4e4e;
  width: 100%;
}

.result_msg {
  position: absolute;
  display: inline-block;
  width: 250px;
  height: 30px;
  font-size: 12px;
  color: #979797;
  padding: 38px 0 0 30px;
}

/* member */
.memberd {
  position: relative;
  width: 360px;
  height: 130px;
  background: #fff;
  margin: 0 auto;
  font-family: ng;
}
.login {
  padding-top: 70px;
}
.login_setd {
  height: 14px;
  margin-bottom: 9px;
  line-height: 0;
}
.login_setd .chk_label {
  display: inline-block;
  position: relative;
  padding-top: 1px;
  font-size: 11px;
  color: #888;
  line-height: 13px;
  vertical-align: top;
  cursor: pointer;
}
.login_form {
  position: relative;
  padding-right: 66px;
}
.login_form_itemp {
  width: 236px;
  height: 41px;
  padding: 0 10px;
  border: 1px solid #dadddf;
  border-right-width: 0;
  vertical-align: middle;
}
.login_form_itemp:first-child {
  border-bottom: 0;
}
.login_form_itemp .txt_inp {
  width: 204px;
  height: 20px;
  margin-top: 10px;
  margin-left: 5px;
  font-size: 13px;
  color: #212121;
  line-height: 20px;
  vertical-align: top;
}
.login_btnd {
  position: absolute;
  top: 0;
  right: 0;
  width: 125px;
  height: 85px;
  background: #333333;
  line-height: 85px;
  font-size: 14px;
  color: #fff;
  text-align: center;
}
.login_setSociald {
  position: absolute;
  height: 10px;
  width: 100px;
  margin: -8px 0 0 231px;
}
.login_help {
  padding-top: 10px;
  font-size: 14px;
  color: #555;
  letter-spacing: -1px;
  line-height: 16px;
  *zoom: 1;
}
.login_help:after {
  display: block;
  clear: both;
  content: '';
}
.mem_id_search {
  margin-left: 7px;
}
.mem_id_search,
.mem_pw_search,
.mem_join {
  float: left;
}
.mem_id_search button:hover {
  color: #15a3e1;
}

.mem_pw_search button:hover {
  color: #15a3e1;
}

.mem_join a:hover {
  color: #15a3e1;
}

.mem_join button:hover {
  color: #15a3e1;
}

.login_help_link {
  font-size: 12px;
  color: #555;
}
.login_help_txt {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
}
.login_help_bar {
  float: left;
  width: 1px;
  height: 8px;
  margin: 4px 13px 0 15px;
  background: #dfdfdf;
  vertical-align: top;
}

/* IE9 이하를 위한 css */
.placeholder {
  font-size: 11px;
  color: #c1c1c1;
}
/* IE10 이상을 위한 css */
input::placeholder {
  color: #c1c1c1;
  font-size: 12px;
  opacity: 1;
} /* 파이어폭스에서 뿌옇게 나오는 현상을 방지하기 위한 css */
input::-webkit-input-placeholder {
  color: #c1c1c1;
  font-size: 12px;
} /* IE */
input:-ms-input-placeholder {
  color: #c1c1c1;
  font-size: 12px;
} /* Firefox */
input:-mos-input-placeholder {
  color: #c1c1c1;
  font-size: 12px;
}

/*아이디찾기 팝업 */
.Findid_popup {
  position: absolute;
  width: 440px;
  height: 358px;
  z-index: 1;
  margin: -40px 0 0 76px;
  background-color: #fff;
  border: 5px solid #4e4e4e;
}

.Findid_titleBox {
  position: relative;
  display: block;
  width: 100%;
  height: 70px;
  background-color: #4e4e4e;
  font-size: 20px;
  color: #fff;
  text-align: center;
  line-height: 70px;
}

.Findid_Box {
  display: block;
  width: 100%;
  height: 189px;
  border-bottom: 1px solid #dbdbdb;
  background-color: #f9f9f9;
}
.Findid_Inner {
  width: 350px;
  height: auto;
  padding: 66px 0 0 92px;
}
.Findid_Inner2 {
  width: 350px;
  height: auto;
  padding: 40px 0 0 92px;
}
.Findid_mobileimg {
  position: absolute;
  display: inline-block;
  width: 30px;
  height: 48px;
  margin: 5px 0 0 -40px;
}
.Findid_Title {
  font-size: 20px;
  font-weight: bold;
}
.Findid_Contents {
  font-size: 16px;
  font-weight: normal;
  color: #7b7b7b;
}
.oknoBox_button.Findid {
  padding-top: 22px;
}

/* 비밀번호 찾기 */
.Findid_Inner.pw {
  padding: 40px 0 0 92px;
}
.Findpw_passwardBox {
  position: relative;
  display: block;
  width: 350px;
  height: 35px;
  background-color: #f3f3f3;
  margin-left: -40px;
  margin-top: 0;
  margin-bottom: 20px;
}
.Findpw_inputBox {
  position: absolute;
  display: inline-block;
  width: 330px;
  height: 35px;
  font-size: 14px;
  padding: 8px 0 0 12px;
  z-index: 1;
}
.Findpw_inputBox input {
  color: #4e4e4e;
  width: 100%;
  font-size: 14px;
}
.Findpw_inputBox:placeholder {
  font-size: 20px;
  color: #c1c1c1;
}

/* 서비스별 도움말 */
.serviecenterMain_categoryBox {
  position: relative;
  display: block;
  width: 995px;
  height: 275px;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  border-top: 2px solid #4e4e4e;
  margin-bottom: 40px;
}
.categoryBox_titleBox {
  position: relative;
  display: block;
  width: 100%;
  height: 79px;
  text-align: center;
  line-height: 78px;
  font-size: 20px;
  font-weight: bold;
}
.categoryBox_subtitleBox {
  position: relative;
  display: block;
  width: 994px;
  height: 40px;
  font-size: 14px;
  background-color: #f9f9f9;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  margin: 0 auto;
}
.subtitle_textBox {
  position: relative;
  display: inline-block;
  width: 160px;
  height: auto;
  font-weight: normal;
  color: #7b7b7b;
  margin: 10 64 0 14;
  line-height: 23px;
  font-family: 'Noto Sans KR Light';
}
.subMaintitleBox {
  display: block;
  width: 100%;
  height: 70px;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  margin-bottom: 40px;
}
.subMainGrayBox {
  position: absolute;
  display: inline-block;
  width: 15px;
  height: 17px;
  background-color: #e7e7e7;
  margin-top: 26px;
}
.subMaintitle {
  position: relative;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  padding: 20px 0 0 27px;
}

.Reconfirm_Box {
  display: block;
  width: 100%;
  height: auto;
  font-size: 14px;
  border: 1px solid #dbdbdb;
  border-top: 2px solid #4e4e4e;
  background-color: #ffffff;
}

.Reconfirm_inner {
  width: 478px;
  height: 110px;
  margin: 27px 0 0 167px;
}

.Reconfirm_GrayBox {
  display: block;
  width: 100%;
  height: 136px;
  background-color: #f9f9f9;
  border-top: 1px solid #efefef;
}

.Reconfirm_img {
  position: absolute;
  display: inline-block;
  width: 68px;
  height: 77px;
  margin: 30px 0 0 50px;
}

/* footer - 정보 */
.info_section {
  border-top: 1px solid #eeeff0;
  background: #333333;
  /* height: 186px; */
  text-align: center;
  
} /*footer 전채 */
.info_section a:hover {
  color: #15a3e1;
}
.info_section .inner {
  /* position: relative;
  padding: 38px 0 50px 0; */
  display: flex;
  justify-content: center;
  gap: 0;
}
.logo_SM {
  background-position: 0 -0px;
  cursor: pointer;
}

/* 개인정보취급방침 */
.info_txt {
  color: #b4b4b4;
  line-height: 16px;
}

.info_txt_bar {
  display: inline-block;
  width: 1px;
  height: 8px;
  background: #5d5e5e;
  vertical-align: middle;
  margin: -2px 10px 0;
}
.copyright {
  color: #b4b4b4;
  font-family: 'arial', 'verdana', dotum, sans-serif;
  margin-top: 17px;
  font-size: 12px;
  line-height: 1.8;
}
.copyright_link {
  color: #b4b4b4;
}

/* 심의 등급 */
.grade {
  position: absolute;
  display: none;
  width: 465px;
  height: 80px;
  background-color: #450102;
  margin: -100px 0 0 933px;
  line-height: 25px;
  border: 1px solid #4e0a0b;
}
.grade_textBox {
  position: relative;
  display: block;
  width: 100%;
  height: 33%;
  border-top: 1px solid #4e0a0b;
}
.grade_textBox:nth-child(1) {
  border-top: none;
}
.grade_textinner {
  position: relative;
  display: inline-block;
  width: 49%;
  height: 100%;
  color: #b4b4b4;
}
.grade_textinner:nth-child(1) {
  border-right: 1px solid #4e0a0b;
}
.grade_innerleft {
  display: inline-block;
  width: 80px;
  height: 94%;
}
.grade_innerright {
  display: inline-block;
  position: relative;
  width: 135px;
  height: 100%;
}
.grade_markimg {
  display: none;
  position: absolute;
  width: 400px;
  height: 82px;
  overflow: hidden;
  margin: -102px 0 0 790px;
}

/* IE10+ */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* member */
  .login_setSociald {
    position: absolute;
    height: 10px;
    width: 100px;
    margin: -8px 0 0 228px;
  }
}

.login-header{
  background-color: #fff;
}

.login-header_menu_opt {
  display: none;
}



.policy {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PolicyTitle {
  display:flex;
  justify-content:center;
  align-items: center;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 600;
}

.OperatingPolicyTitle {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
}

.PolicyTitle2 {
  font-size: 15px;
  font-weight: 600
}

.policy2 {
  width: 500px;
  margin-top: 15px;
  font-size: 14px;
  line-height: 3
}

ol {
  padding-left: 0;
  list-style-type: none !important;
  counter-reset: my-counter;
  margin-left: 30px !important
}

 li {
  position: relative; /* Required for absolute positioning */
  display: block;
  clear: left; /* Ensure no text wraps around */
}

 li::before {
  counter-increment: my-counter;
  content: counter(my-counter);
  width: 14px;
  height: 14px;
  line-height: 13px;
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid black;
  margin-right: 0.625rem;
  vertical-align: middle;
  position: absolute;
  left: -1.875rem; /* Adjust as needed */
  top: 14px;
  align-item: center;
  font-size: 12px;
}

  ol ol{
    padding-left: 0;
    list-style: auto !important;
    margin-left: 28.5px !important;
    counter-reset: item;
  }
  ol ol li {
    list-style: auto !important;
  }
  ol ol li::before {
    counter-increment: item;
    content: counter(item)". ";
    border: none !important;
    font-size: 15px;
    top: 14.5px;
  }


  .custom_ol{
    padding-left: 0;
    list-style: auto !important;
    margin-left: 28.5px !important;
  }
  .custom_ol li {
    list-style: auto !important;
  }
  .custom_ol li::before {
    content: "-";
    border: none !important;
    font-size: 14px;
    top: 13.5px;
  }


  table {
      border-collapse: collapse;
      width: 100%;
    }

    td, th {
       border: 2px solid black;
       text-align: left;
       padding: 8px;
       text-align: center;
       font-size: 15px;
    }

    th{
        font-size: 17px;
        font-weight: 600;
        background-color: rgb(212, 234, 251);
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: 125px;
    }

    .PrivacyTable1 td{
       border: 2px solid black;
       text-align: left;
       padding: 8px;
    /*    text-align: center; */
       font-size: 15px;
    }

    .PrivacyTable1 th:nth-child(1),
    td:nth-child(1) {
      width: 125px;
      text-align: center;
    }

    .span_space {
        padding: 0.719rem;
    }

    .span_space2 {
        padding: 4.5px;
    }

    .PolicyContent {
        margin-top: 0 !important;
    }

  @media (max-width: 640px)
    {
        .policy2{
            width: unset;
            font-size: 2.1875vw;
        }

        .PolicyTitle {
           font-size: 2.3vw;
        }

        .PolicyTitle2{
           font-size: 2.2vw;
        }

        ol {
          margin-left: 5.1vw !important
        }

        li::before {
          top: 2vw;
          width: 3vw;
          height: 3vw;
          font-size: 2.1875vw;
          line-height: 3vw;
          left: -5vw;
          border: 0.25vw solid black
        }

        ol ol{
            margin-left: 2.8vw !important;
        }

        ol ol li::before {
            font-size: 2.1875vw;
            top: 1.9vw !important;
            left: -3.5vw;
        }


        .custom_ol{
            margin-left: 0 !important;
        }

        .custom_ol li::before {
            font-size: 2.5vw;
            top: 1.5vw !important;
        }

        .PrivacyTable1 td {
          font-size: 2.1875vw;
        }


        table {
          border-collapse: collapse;
          width: 100%;
        }

        td, th {
           border: 2px solid black;
           text-align: left;
           padding: 8px;
           text-align: center;
           font-size: 2.1875vw;
        }

        th{
            font-size: 2.2vw;
            font-weight: 600;
            background-color: rgb(212, 234, 251);
        }

        th:nth-child(1),
        td:nth-child(1) {
          width: 19vw
        }

        .PrivacyTable1 td{
           border: 2px solid black;
           text-align: left;
           padding: 8px;
        }

        .PrivacyTable1 th:nth-child(1),
        td:nth-child(1) {
          width: 19vw;
          text-align: center;
        }

        .span_space {
            padding: 0.719rem;
        }

        .span_space2 {
            padding: 4.5px;
        }

    }

.info_area li::before {
  list-style: none;
  border: unset;
  content: unset;
}

.tabcontentGame li {
  clear: unset;
}

.tabcontentGame li::before {
  list-style: none;
  border: unset;
  content: unset;
  clear: unset;
}