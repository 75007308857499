.log-img {
    margin-right: 40px;
    width: 93px;
}

.contentsinterval a:hover {
    color: #15a3e1;
}

.menu_popup {
    display: none;
    cursor: default;
    position: absolute;
    margin-top: 106px;
}

.AllgameBox_innerBox2 {
    border-right: 1px solid #efefef;
    padding-right:0;
}

.swiper16 video {
    width: 380%;
    height: 50rem;
}

.BoxClass {
    width:283px;
    height:283px;
    position:relative;
    display:flex;
    justify-content: center;
    transition: 1s;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.GoStopBoxClass {
    background-image:url('../file/go_stop_box_org.png');
}

.GoStopBoxClass:hover {
    background-image:url('../file/go_stop_box.png');
}

.BadukiBoxClass {
    background-image: url('../file/baduki_box_org.png')
}

.BadukiBoxClass:hover {
    background-image: url('../file/baduki_box.png')
}

.PokerBoxClass {
    background-image: url('../file/7_poker_box_org.png');
}

.PokerBoxClass:hover {
    background-image: url('../file/7_poker_box.png');
}

.SmplBoxClass {
    background-image: url('../file/smpl_box_org.png')
}

.SmplBoxClass:hover {
    background-image: url('../file/smpl_box.png')
}

.main_div_images img {
    width: 221px;
    height: 222px;
    z-index: 2;
}

.main_div_images img:hover .GoStopBoxClass{
    background-image:url('../file/go_stop_box.png');
}

.image-shadow {
    width: 5.99063rem;
    height: 5.99063rem;
    flex-shrink: 0;
    border-radius: 6.09519rem;
    background: lightgray 50% / cover no-repeat;
    opacity: 0.30;
    box-shadow: 2px 2px 5px 0 rgba(95, 95, 95, 0.90), -1.597px -1.597px 4px 0px rgba(255, 255, 255, 0.90), 2px -1.597px 3.195px 0 rgba(95, 95, 95, 0.20), -1.597px 1.597px 4px 0px rgba(95, 95, 95, 0.20), -1.597px -1.597px 4px 0px rgba(95, 95, 95, 0.50) inset, 1.597px 1.597px 4px 0px rgba(255, 255, 255, 0.30) inset;
}

.tab_content {
    display: grid;
    padding-top: 5px;
    margin-left: 0;
    gap:15px;
    grid-template-columns: repeat(5, 0fr);
}

.game_details {
    width: 21.6rem;
    margin-left: 2rem;
    height: 74px;
    color: #1C1C1C;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    word-wrap: break-word
}

.info_top_section_inner_a{
    color:#2C2C31;
    font-size: 25px;
    font-weight: 350;
    line-height: 36px;
    letter-spacing: 0;
}

.info_top_section_inner_a:hover {
    color: #15a3e1
}

.swiper-banner-slide {
    height: 200px;
    transition-duration: 0ms;
    transform: translate3d(0px, -820px, 0px);
    background-repeat : no-repeat;
    cursor: pointer;
}

.game-swiper1 {
    height: 200px;
}


.footer-container {
    height: 20px;
    font-size: 15px;
}

.footer-notice {

}

.footer-notice:hover {
    color: #0f69b4;
}
