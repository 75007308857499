@media only screen and (max-width: 1280px) {
    .m_header {
        position: relative;
        width: 100%;
        z-index: 2;
        height: 15vw;
        background-color: white;
    }

    .m_header_sticky {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 20;
        height: 15vw;
        background-color: white;
    }

    .m_contents {
        width: 93.75vw;
        height: 100%;
        margin: 0 auto;
        padding-top: 0;
    }

    .m_sm_logo {
        position: absolute;
        display: block;
        width: 15vw;
        height: 15.625vw;
        margin-left: 40vw;
        background-image: url("../images/mobile_img/SM Logo 1.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
    }

    .m_menu_img {
        background-image: url("../images/mobile_img/lucide_menu.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
        position: absolute;
        display: inline-block;
        width: 4.6875vw;
        height: 100%;
    }

    .m_popup_dim_box {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.7;
        z-index: 30;
    }

    .m_popup_close_img {
        position: fixed;
        width: 3.75vw;
        height: 3.5938vw;
        margin: 3.125vw 0 3.125vw 42vw;
        z-index: 5;

        background-image: url("../images/mobile_img/Icon-close.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
    }

    .m_menu_popup_box {
        position: fixed;
        width: 50vw;
        height: 100%;
        font-size: 4.6875vw;
        background-color: #252525;
        z-index: 30;
    }

    .m_popup_contents_box {
        position: fixed;
        height: 78.125vw;
        padding: 10.1563vw 0 0 10vw;
    }

    .m_popup_contents_box div {
        display: block;
        width: auto;
        height: 6.25vw;
        margin-top: 4.6875vw;
        cursor: pointer;
        color: #ffffff;
    }

    .m_dualgo_banner {
        width: 100vw;
        height: 100vw;
        background-image: url("../images/mobile_img/Gostop (4).png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
    }

    .m_baduki_banner {
        width: 100vw;
        height: 100vw;
        background-image: url("../images/mobile_img/Baduki (3).png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
    }

    .m_poker_banner {
        width: 100vw;
        height: 100vw;
        background-image: url("../images/mobile_img/7 poker (3).png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
    }

    .m_smpl_banner {
        width: 100vw;
        height: 100vw;
        background-image: url("../images/mobile_img/SMPL 2.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
    }

    .m_swiper_pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3vw;
        gap: 1vw;
    }

    .m_swiper_pagination_bullet {
        width: 3vw;
        height: 3vw;
        display: inline-block;
        border-radius: 100%;
        background: #000;
        opacity: 0.2;
        margin-left: 1vw;
    }


    .m_active_bullet {
        opacity: 1;
        background: var(--swiper-pagination-color, var(--swiper-theme-color))
    }

    .game_box_contents {
        width: 93.75vw;
        height: auto;
        margin: 0 auto;
        padding-top: 10vw;
    }

    .m_game_content {
        width: 93.75vw;
        height: auto;
        margin: 0 auto;
        padding-top: 8vw;
    }

    .m_section_tit_box_inner {
        position: relative;
        width: 100%;
        font-size: 5.6250vw;
        display: inline-block;
        height: 7.8125vw;
        color: #4e4e4e;
    }

    .m_recommended_game_box {
        position: relative;
        display: block;
        width: 100%;
        height: 31.25vw;
        margin-top: 3.125vw;
        background-color: #ffffff;
    }

    .m_recommended_game_img {
        position: relative;
        display: inline-block;
        width: 46.875vw;
        height: 100%;

        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
    }

    .m_img_dualgo {
        background-image: url("../images/mobile_img/gostop.png");
    }

    .m_img_baduki {
        background-image: url("../images/mobile_img/baduki_mobile.png");
    }

    .m_img_poker {
        background-image: url("../images/mobile_img/poker_mobile.png");
    }

    .m_img_smpl {
        background-image: url("../images/mobile_img/smpl_mobile.png");
    }

    .m_recommended_game_txt {
        position: absolute;
        display: inline-block;
        width: 37.5vw;
        height: 24.0625vw;
        font-size: 3.4375vw;
        color: #888888;
        margin: 3.1250vw 0 0 4.6875vw;
    }

    .m_play_game {
        position: absolute;
        font-size: 3.1250vw;
        color: #ffffff;
        background-color: #15a3e1;
        padding: 1.875vw 23.75vw 1.875vw 2.1875vw;
        margin-top: 3.75vw;
        border-radius: 1.5625vw;
    }

    .m_play_game_img {
        position: absolute;
        width: 1.25vw;
        height: 2.5vw;
        background-image: url(../images/mobile_img/Icon-buttonarrow.png);
        background-size: 100%;
        background-repeat: no-repeat;
        margin: -3.125vw 0 0 16.25vw;
    }

    .m_play_store_img_2 {
        position: absolute;
        width: 6.875vw;
        height: 6.875vw;
        display: inline-block;
        margin-top: -1.24vw;
        margin-left: 8.5vw;

        background-image: url("../images/mobile_img/Icon-play.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
    }

    .m_play_store_img_3 {
        position: absolute;
        width: 6.875vw;
        height: 6.875vw;
        display: inline-block;
        margin-top: -1.24vw;
        margin-left: 16vw;

        background-image: url("../images/mobile_img/Icon-apple.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
    }


    .m_fbanner {
        position: relative;
        display: block;
        width: 100%;
        height: 47vw;
        margin-top: 12.5vw;
        background-size: 200%;
        background-color: black;
    }

    .m_game_box {
        display: inline-block;
        width: 45.3125vw;
        height: 56.875vw;
        padding-top: 4.125vw;
        text-align: center;
        padding-left: 1.5625vw;
    }


    .m_game_img_box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .m_game_box_img_div {
        display: block;
        cursor: pointer;

        width: 42vw;
        height: 42vw;
        flex-shrink: 0;
        border-radius: 6.09519rem;
        background: lightgray 50% / cover no-repeat;
        box-shadow: 2px 2px 5px 0 rgba(95, 95, 95, 0.90), -1.597px -1.597px 4px 0px rgba(255, 255, 255, 0.90), 2px -1.597px 3.195px 0 rgba(95, 95, 95, 0.20), -1.597px 1.597px 4px 0px rgba(95, 95, 95, 0.20), -1.597px -1.597px 4px 0px rgba(95, 95, 95, 0.50) inset, 1.597px 1.597px 4px 0px rgba(255, 255, 255, 0.30) inset;

    }

    .m_game_box_img_janggi {
        background-image: url("../images/mobile_img/mobile_Gjanggi.jpg");
    }

    .m_game_box_img_matching {
        background-image: url("../images/mobile_img/mobile_Gmatching3.png");
    }

    .m_game_box_img_sudda {
        background-image: url("../images/main_img/G-sudda.jpg");
    }

    .m_game_box_img_gostop {
        background-image: url("../images/mobile_img/mobile_Ggostop.jpg");
    }

    .m_game_box_img_battlesniper {
        background-image: url("../images/mobile_img/mobile_Gbattlesniper.jpg");
    }

    .m_game_box_img_probaseball {
        background-image: url("../images/mobile_img/mobile_Gprobaseball.jpg");
    }

    .m_game_title_text {
        font-size: 4.6875vw;
        color: #3f3f3f;
        font-weight: bold;
    }

    .m_game_explain_text {
        font-size: 3.1250vw;
        color: #888888;
    }

    .m_footer_box {
        bottom: 0;
        width: 100%;
        max-height: 19.375vw;
        height: 19.375vw;
        color: #9c9e9e;
        font-size: 3.125vw;
        background-color: #333333;
        text-align: center;
    }

    .m_footer_contents {
        width: 93.75vw;
        height: auto;
        margin: 0 auto;
        text-align: center;
    }

    .m_footer_link {
        width: auto;
        padding-top: 5.3125vw;
    }

    .m_footer_link span {
        cursor: pointer;
    }

    .m_info_txt_bar {
        display: inline-block;
        width: 0.1563vw;
        height: 1.25vw;
        background: #5d5e5e;
        vertical-align: middle;
        margin: -0.3125vw 1.5625vw 0;
    }

    .m_notice_box {
        width: 100%;
        height: 12.5vw;
        background-color: #ffffff;
        line-height: 12.5000vw;
        text-align: center;
        margin-top: 12.5vw;
    }

    .m_notice_box_content {
        padding-top: 0;
        display: flex;
        gap: 3vw;
        width: 93.75vw;
        height: auto;
        margin: 0 auto;
    }

    .m_notice_content {
        height: 12.5vw;
        overflow: hidden;
        text-align: center;
        display: block;
        font-size: 3.1250vw;
        color: #7b7b7b;
    }

    .m_top_game_section {
        display: block;
        width: 100%;
        height: 52.3438vw;
        margin: 0 auto;
        text-align: center;
    }

    .m_other-game-section {
        width: auto;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 200%;
    }

    .m_title-text-other-game {
        width: 100%;
        height: 7.5vw;
        color: #ffffff;
        font-size: 5.0000vw;
        text-align: center;
        padding: 12.5vw 0 0 0;
        margin: 0 auto 6.25vw auto;
    }

    .m_info_img_box {
        width: auto;
        height: auto;
        text-align: center;
    }

    .m_info_img_box img {
        width: 86.4063vw;
        height: 24.2188vw;
        text-align: center
    }

    .m_info-text-other-game {
        position: relative;
        display: inline-block;
        width: 28.125vw;
        height: 7.0313vw;
        font-size: 3.1250vw;
        color: #8f8f8f;
        padding-right: 0;
    }

    .m_other-game-info-area {
        position: relative;
        display: block;
        width: auto;
        height: 110vw;
    }

    .m_explain-text-other-game {
        text-align: center;
        font-size: 3.1250vw;
        color: #acacac;
    }

    .m_point_box {
        width: 63.5938vw;
        height: 38.125vw;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .m_div_height {
        height: 7.8125vw;
    }

    .m_point_b_text {
        position: relative;
        display: inline-block;
        width: 100%;
        font-size: 5.0000vw;
        color: #ffffff;
        text-align: center;
    }

    .m_point_s_text {
        position: relative;
        display: inline-block;
        width: 100%;
        font-size: 3.1250vw;
        color: #0169cc;
        line-height: 1.5;
        text-align: center;
    }

    .m_point_s_text em {
        font-weight: bold;
    }

    .m_point_background {
        position: relative;
        display: block;
        width: 63.2813vw;
        height: 76.5625vw;
        margin: 1.5625vw auto;
        background-size: 100%;
    }

    .m_point_img_1 {
        background-image: url(../images/battlesniper_img/m_battlesniper_point1.jpg);
    }

    .m_point_img_2 {
        background-image: url(../images/battlesniper_img/m_battlesniper_point2.jpg);
    }

    .m_point_img_3 {
        background-image: url(../images/battlesniper_img/m_battlesniper_point3.jpg);
    }

    .m_title-text-other-game em {
        padding-bottom: 0.7813vw;
        border-bottom: 0.3125vw solid #acacac;
    }

    .m_top_my_section {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 17.1875vw;
        background-position: center;
        background-image: url(../images/servicecenter_img/servicecenter_P01.jpg);
        background-repeat: repeat-x;
    }

    .m_top_my_section span {
        color: #4e4e4e;
    }

    .m_all_game_content {
        width: 93.75vw;
        height: 200vw;
        margin: 0 auto;
        padding-top: 6.25vw;
    }

    .m_all_game_box {
        width: 100%;
        height: auto;
        background-color: #ffffff;
        border: 1px solid #dbdbdb;
    }

    .m_all_game_box_title {
        width: 100%;
        height: 13.9063vw;
        font-size: 4.6875vw;
        color: #4e4e4e;
        line-height: 13.7500vw;
    }

    .m_all_game_inner {
        width: 89.0625vw;
        height: 100%;
        margin: 0 0 0 4.5313vw;
    }

    .m_all_game_title_img {
        position: absolute;
        width: 14.8438vw;
        height: 9.375vw;
        margin: -9.2188vw 0 0 73.6875vw;
    }

    .m_all_game_title_img img {
        width: 100%;
        height: auto;
    }

    .m_all_game_content_box {
        width: 100%;
        height: 10.625vw;
        font-size: 3.1250vw;
        line-height: 10.6250vw;
        background-color: #f9f9f9;
        border-top: 1px solid #efefef;
    }

    .m_all_game_content_txt {
        display: inline-block;
        width: 29.2188vw;
        height: 100%;
    }

    .m_shop_box {
        width: 100%;
        height: auto;
        background-color: #ffffff;
        border: 1px solid #dbdbdb;
        border-top: 1px solid #4e4e4e;
    }

    .m_card_img_box_game {
        display: block;
        position: relative;
        z-index: 1;
        height: auto;
        overflow: hidden;
        margin: 0 auto;
        cursor: pointer;
    }

    .m_card_img_box_game img {
        width: 15.3125vw;
    }

    .m_shop_card_tit {
        overflow: hidden;
        display: block;
        margin: 2.0313vw 0.0000vw 0.0000vw 0.0000vw;
        font-size: 2vw;
        font-weight: bold;
        color: #212121;
        line-height: 20px;
        text-overflow: ellipsis;
    }


    .m_shop_avatar_count {
        overflow: hidden;
        display: block;
        font-size: 1.5vw;
        font-weight: bold;
        color: #212121;
        text-overflow: ellipsis;
    }

    .m_shop_card_txt {
        margin: 0;
        font-size: 1.8750vw;
    }

    .m_card_price {

    }

    .m_shop_tab_content {
        display: grid;
        grid-template-columns: repeat(4, 0fr);
        gap: 8vw;
        margin-top: 5vw;
    }

    .m_shop_contents {
        width: 93.75vw;
        height: auto;
        margin: 0 auto;
        padding-top: 6.25vw;
        padding-bottom: 10vw;
    }

    .m_buy_button {
        width: 8.125vw;
        height: 4.375vw;
        border: 1px solid #dbdbdb;
        border-radius: 0.7813vw;
        font-size: 1.875vw;
    }

    .m_buy_bag_button {
        width: 15.3125vw;
        height: 6.2500vw;
        border: 1px solid #dbdbdb;
        border-radius: 0.7813vw;
        font-size: 1.875vw;
        margin-top: 1vw;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .m_shop_col_type1 {
        background-color: #ffffff;
        text-align: center;
        /*height: 50vw;*/
    }

    .m_tab_links_a {
        display: inline-block;
        width: 46.4063vw;
        height: 10.3125vw;
        background-color: #4e4e4e;
        border-top-left-radius: 0.7813vw;
        border-top-right-radius: 0.7813vw;
        text-align: center;
        font-size: 4.6875vw;
        color: #ffffff;
    }

    .m_shop_section_2 {
        width: 100%;
        height: auto;
        background-color: #ffffff;
        border: 1px solid #dbdbdb;
        border-top: 1px solid #4e4e4e;
    }

    .m_buy_shop_center_wrap {
        width: 94vw;
        height: auto;
        margin: 0;
        padding-top: 5.3125vw;
    }

    .m_buy_avatar_img {
        position: absolute;
        width: 15.3125vw;
        height: 22.6563vw;
        background-color: #f3f3f3;
        padding: 9.3750vw;
        margin: 15.6250vw 0 0 2.3438vw;
        border-radius: 1.5625vw;
    }

    .m_buy_avatar_img img {
        width: 15.3125vw;
        height: 22.6563vw;
    }

    .m_buy_title_box {
        width: 100%;
        height: 7.8125vw;
        font-size: 4.6875vw;
        font-weight: bold;
        text-align: center;
        padding-top: 7.8125vw;
        border-bottom: 0.1563vw solid #dbdbdb;
    }

    .m_buy_contents {
        width: 70%;
        height: 94.025vw;
        background-color: #ffffff;
        margin: unset;
    }

    .m_buy_contents_box {
        width: 100%;
        height: 7.8125vw;
        font-weight: bold;
        border-bottom: 0.1563vw solid #dbdbdb;
    }

    .m_buy_contents_title_box {
        display: inline-block;
        width: 15.625vw;
        height: 100%;
        margin-left: 6.25vw;
        font-size: 2.5000vw;
        margin-top: 1.5625vw;
    }

    .m_buy_contents_inner {
        display: inline-block;
        width: 70%;
        height: 100%;
        margin-left: 39.0625vw;
    }

    .m_buy_section {
        position: relative;
        min-height: 54.8438vw;
        height: 96.025vw;
        border: 0.1563vw solid #e2e2e2;
        background-color: #ffffff;
    }

    .m_buy_contents_text_box {
        position: absolute;
        display: inline-block;
        width: auto;
        height: 7.8125vw;
        font-size: 2.1875vw;
        font-weight: normal;
    }

    .m_count_number {
        position: absolute;
        display: inline-block;
        width: 6.25vw;
        height: 6.25vw;
        background-color: #ededed;
        font-size: 2.5000vw;
        text-align: center;
        margin-top: 0.7813vw;
    }

    .m_count_number input {
        width: 100%;
        color: #4e4e4e;
        font-size: 2vw;
        text-align: center;
    }

    .m_count_number_up_img {
        position: absolute;
        width: 3.125vw;
        height: 3.125vw;
    }

    .m_countnumber_down_img {
        position: absolute;
        width: 3.125vw;
        height: 3.125vw;
        margin-top: 3.125vw;
    }

    .m_buy_money_text_box_inner {
        position: absolute;
        display: block;
        width: 45.9375vw;
        height: 7.8125vw;
        color: #15a3e1;
        font-size: 2vw;
        line-height: 7.5vw;
        margin: 0 auto;
    }

    .m_buy_total_money_box {
        width: 100%;
        height: 7.8125vw;
        font-size: 2.5000vw;
        text-align: right;
    }


    .m_buydrop_box_select {
        display: inline-block;
        position: relative;
        width: 21.8750vw;
        height: 100%;
        font-size: 2.1875vw;
        color: #333;
        border: none;
        z-index: 1;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo4YTY5ZWFjYy04ZDEyLWMyNDAtYWEzNC1jNDY2ZTU4ZmViNTMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NkZFMDZERUEzQ0MwMTFFQTg0ODJDRDgwNTMyODFDMUIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NkZFMDZERTkzQ0MwMTFFQTg0ODJDRDgwNTMyODFDMUIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OTgzOTMxODYtODlhYS0yZDRkLTkyNmMtMmNlNTM5ZDdjZjVlIiBzdFJlZjpkb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6M2E3MGRiNmQtMzg3NS1kZTQ4LTg2ZTctM2JkYTE1MTZhMTVjIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+qcxt4wAAAItJREFUeNpi+P///wIgfgfEPkDMgAf7AvFbIJ4P4nz6DwE/gNgFhwZXIP4JVfcBJJDxHwG+ArE1mgZbqDgI/AOph0lUIGn8AMQmUHEzKB8GSkHiyCY2I0m+BuJIqB9goBGmFt3tE/5jB33I6tA1MQLxbDQNM6HiODWBMDMQzwDiz0A8HYiZ0NUABBgA2r9i6UlmMCoAAAAASUVORK5CYII=) no-repeat 95% 50%;
        appearance: none;
        padding: 0.7813vw 2.1875vw;
    }

    .m_buy_money_text_box {
        width: 100%;
        height: 15.625vw;
        background-color: #f3f3f3;
    }

    .m_quit_ok_no_button_img {
        position: absolute;
        width: 1.4063vw;
        height: 2.1875vw;
        margin: -2.8125vw 0 0 14.8438vw;
    }

    .m_quit_ok_no_button_img img {
        width: 1.4063vw;
        height: 2.1875vw;
    }

    .m_count_number_up_down_box {
        position: absolute;
        display: inline-block;
        width: 3.125vw;
        height: 6.25vw;
        margin-top: 0.625vw;
        margin-left: 6.25vw;
        border: 0.1563vw solid #ededed;
    }

    .m_shop_game_center_warp_main {
        padding-bottom: unset;
    }

    .m_qa_content {
        width: 93.75vw;
        height: auto;
        margin: 0 auto;
        padding-top: 4.6875vw;
        padding-bottom: 6vh;
    }

    .m_qa_title_box {
        width: 100%;
        height: 8.125vw;
        font-size: 4.6875vw;
        color: #4e4e4e;
        font-family: 'Noto Sans KR Medium';
        cursor: pointer;
    }

    .m_qa_box {
        display: block;
        width: 100%;
        height: auto;
        background-color: #f9f9f9;
        cursor: pointer;
    }

    .m_qa_pagination_box {
        display: block;
        width: 100%;
        height: 7.5000vw;
        background-color: #f9f9f9;
    }

    .m_qa_pagination_box_2 {
        border-top: 1PX solid #efefef;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .m_qa_pagination_active {
        background-color: #15a3e1;
        color: #ffffff !important;
    }

    .m_qa_pagination_buttons {
        width: 6.2500vw;
        height: 7.5000vw;
        font-size: 2.5000vw;
        color: #4e4e4e;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .m_qa_box_border {
        border: 1PX solid #dbdbdb;
        border-top: 2PX solid #4e4e4e;
    }


    .m_aq_title_box {
        display: block;
        width: 100%;
        height: auto;
        font-size: 3.1250vw;
        background-color: #ffffff;
        border-top: 1PX solid #dbdbdb;
        cursor: pointer;
    }

    .m_aq_title_box_2 {
        display: block;
        width: 100%;
        height: 10.6250vw;
        font-size: 2.5000vw;
        background-color: #f9f9f9;
        border-top: 1PX solid #efefef;
        color: #15a3e1;
        cursor: pointer;
    }

    .m_aq_title_box_3 {
        display: block;
        width: 100%;
        height: auto;
        font-size: 2.5000vw;
        background-color: #ffffff;
        /*color: #15a3e1;*/
        cursor: pointer;
    }

    .m_qa_inner {
        display: inline-flex;
        width: 82.1875vw;
        height: 4.6875vw;
        padding: 3.5938vw 0 3.5938vw 4.375vw;
    }

    .m_qa_inner_2 {
        display: inline-flex;
        width: 82.1875vw;
        height: auto;
        padding: 3.5938vw 0 3.5938vw 4.375vw;
    }

    .m_qa_inner_3 {
        width: 82.1875vw;
        height: auto;
    }


    .m_quit_ok_no_button {
        background-color: #15a3e1;
        border-radius: 0.7813vw;
        color: #fff;
        display: inline-block;
        font-size: 2.5vw;
        height: 6.5625vw;
        margin-right: 1vw;
        padding-bottom: 0.3125vw;
        padding-left: 2.1875vw;
        position: relative;
        text-align: left;
        width: 18.75vw;
    }

    .m_quit_ok_no_button_2 {
        width: 4.6875vw;
        height: 3.9063vw;
        background-color: #15a3e1;
        color: #fff;
        display: inline-block;
        font-size: 2.1875vw;
        margin-right: 1vw;
        position: relative;
        text-align: center;
        padding-bottom: 0.3125vw;
        border-radius: 0.7813vw;
    }


    .m_qa_button_img {
        position: absolute;
        display: inline-block;
        width: 1.7188vw;
        height: 1.0938vw;
        margin-top: 5.625vw;

        background-image: url("../images/membership_img/Icon-down.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    .m_membership_box {
        display: block;
        width: 100%;
        height: auto;
        min-height: 42.1875vw;
        border: 1px solid #dbdbdb;
        border-top: 2px solid #4e4e4e;
        background-color: #f9f9f9;
    }

    .m_center_warp {
        width: 93.7500vw;
        padding-top: 4.6875vw;
        padding-bottom: 46.8vh;
        margin: 0 auto;
    }

    .m_login {
        padding-top: 10.9375vw;
    }

    .m_login_help {
        padding-top: 1.5625vw;
        font-size: 2.5000vw;
        color: #555;
        letter-spacing: -0.1563vw;
        line-height: 2.5000vw;
        margin-left: 17vw;
    }

    .m_login_help_bar {
        float: left;
        width: 0.1563vw;
        height: 1.25vw;
        margin: 0.625vw 2.0313vw 0 2.3438vw;
        background: #dfdfdf;
        vertical-align: top;
    }

    .login_set {
        height: 2.1875vw;
        margin-bottom: 1.4063vw;
        line-height: 0;
    }

    .m_login_form {
        position: relative;
        padding-right: unset;
        display: flex;
        justify-content: center;
    }

    .m_login_form_itemp {
        width: 36.875vw;
        height: 6.4063vw;
        padding: 0 1.5625vw;
        border: 1px solid #dadddf;
        border-right-width: 0;
        vertical-align: middle;
    }

    .m_login_form_itemp .txt_inp {
        width: 32.0063vw;
        height: 3.125vw;
        margin-top: 1.5625vw;
        margin-left: 0.7813vw;
        font-size: 2.5000vw;
        color: #212121;
        line-height: 3.1250vw;
        vertical-align: top;
    }

    .m_login_btnd {
        position: relative;
        top: 0;
        right: 0;
        width: 19.5313vw;
        height: 13.2813vw;
        background: #333333;
        line-height: 13.2813vw;
        font-size: 3.1250vw;
        color: #fff;
        text-align: center;
    }

    .m_login_help button {
        font-size: 2.5000vw;
        letter-spacing: -1px;
        line-height: 16px;
        color: #555;
    }

    .find_id_title_box {
        width: 100%;
        height: 8.125vw;
        font-size: 4.6875vw;
        color: #4e4e4e;
    }

    .find_id_box {
        display: block;
        width: 100%;
        height: auto;
        min-height: 33.125vw;
        border: 0.1563vw solid #dbdbdb;
        border-top: 0.3125vw solid #4e4e4e;
        background-color: #f9f9f9;
    }

    .find_id_inner {
        width: 57.8125vw;
        height: auto;
        padding: 12.0313vw 0 0 26.875vw;
    }

    .find_id_inner.pw {
        padding: 6.875vw 0 0 26.875vw;
    }


    .find_id_mobile_img {
        position: absolute;
        display: inline-block;
        width: 4.6875vw;
        height: 7.5vw;
        margin: 0.7813vw 0 0 -6.25vw;
    }

    .find_id_mobile_img img {
        width: 100%;
    }

    .find_id_title {
        font-size: 3.1250vw;
        font-weight: bold;
    }

    .find_id_contents {
        font-size: 2.5000vw;
        font-weight: normal;
        color: #7b7b7b;
    }

    .m_ok_btn_box {
        display: flex;
        justify-content: center;
        padding-top: 6.25vw;
        text-align: center;
        gap: 1vw;
    }

    .m_ok_no_button {
        position: relative;
        display: inline-block;
        font-size: 2.5000vw;
        color: #ffffff;
        background-color: #15a3e1;
        padding: 1.4063vw 7.1875vw 1.4063vw 2.1875vw;
        margin-top: 0.9375vw;
        border-top-left-radius: 0.7813vw;
        border-bottom-left-radius: 0.7813vw;
        border-top-right-radius: 0.7813vw;
        border-bottom-right-radius: 0.7813vw;
    }

    .m_ok_no_button_img {
        position: absolute;
        width: 1.4063vw;
        height: 2.1875vw;
        margin: -2.8125vw 0 0 8.2813vw;

        background-image: url("../images/mypage_img/Icon-button.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    .find_pw_passward_box {
        position: relative;
        display: block;
        width: 54.6875vw;
        height: 7.8125vw;
        background-color: #f3f3f3;
        margin-left: -6.25vw;
        margin-top: 0;
        margin-bottom: 3.125vw;
    }

    .find_pw_input_box {
        position: absolute;
        display: inline-block;
        width: 51.5625vw;
        height: 7.8125vw;
        font-size: 3.1250vw;
        padding: 1.5625vw 0 0 1.875vw;
        z-index: 1;
    }

    .find_pw_input_box input {
        color: #4e4e4e;
        width: 100%;
        font-size: 3.1250vw;
    }

    .sub_main_title {
        width: 100%;
        height: 8.125vw;
        font-size: 4.6875vw;
        color: #4e4e4e;
    }

    .m_membership_title_Box {
        position: relative;
        display: block;
        width: 100%;
        height: 11.5625vw;
        font-size: 2.5000vw;
        font-weight: bold;
        background-color: #ffffff;
        border-bottom: 1px solid #efefef;
    }

    .m_membership_title {
        display: block;
        width: 100%;
        height: 8.75vw;
        padding: 3.75vw 0 0 4.6875vw;
    }

    .m_membership_uncheck_img {
        position: absolute;
        display: inline-block;
        width: 3.4375vw;
        height: 3.4375vw;
        margin: 0.3125vw 0 0 -0.9375vw;
    }

    .membership_contentsBlock {
        display: block;
        width: 85.9375vw;
        height: 6.25vw;
        color: #7b7b7b;
        font-weight: normal;
        font-size: 2.1875vw;
        margin: 3.125vw 0 0 4.6875vw;
    }

    .m_membership_check_img {
        position: absolute;
        display: inline-block;
        width: 3.4375vw;
        height: 3.4375vw;
        margin: -0.1563vw 0 0 4.6875vw;
    }

    .m_membership_check_img img {
        width: 100%;
        height: auto;
    }

    .m_membership_uncheck_img img {
        width: 100%;
        height: auto;
    }

    .membership_contentsText {
        display: inline-block;
        width: auto;
        margin-left: 9.375vw;
        padding-right: 9.375vw;
        margin-top: -0.9375vw;
    }

    .Show_agree {
        position: absolute;
        width: auto;
        height: auto;
        margin: -4.6875vw 0 0 62.5vw;
    }

    .m_center_warp_inner {
        width: unset;
    }

    .m_membership_contents {
        display: block;
        width: 85.9375vw;
        height: 6.25vw;
        color: #7b7b7b;
        font-weight: normal;
        font-size: 2.1875vw;
        margin: 3.125vw 0 0 4.6875vw;
    }

    .membership_down_img {
        position: absolute;
        display: inline-block;
        width: 1.7188vw;
        height: 1.0938vw;
        margin-top: 0.9vw;
        margin-left: 1vw;
    }

    .membership_mobile_icon {
        position: absolute;
        display: inline-block;
        width: 4.6875vw;
        height: 7.5vw;
        margin: -1.875vw 0 0 10.9375vw
    }

    .m_member_agreement_contents {
        display: block;
        width: 84.0625vw;
        font-weight: normal;
        font-size: 2.1875vw;
        margin: 0 0 0 4.6875vw;
        line-height: 1.4;
        overflow: auto;
        background-color: #fff;
        height: 268px;
        border: 1px solid #dbdbdb;
    }


    .m_member_contents {
        width: 93.75vw;
        height: auto;
        margin: 0 auto;
        padding-top: 4.6875vw;
        padding-bottom: 15.5vh
    }

    .m_member_title_box {
        width: 100%;
        height: 8.125vw;
        font-size: 4.6875vw;
        color: #4e4e4e;
    }

    .my_contents_box {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        background-color: #ffffff;
        border-left: 1px solid #dbdbdb;
        border-right: 1px solid #dbdbdb;
        border-top: 2px solid #4e4e4e;
    }

    .my_contents {
        display: block;
        width: 100%;
        height: 18.5938vw;
        font-size: 3.1250vw;
        border-bottom: 1px solid #dbdbdb;
        line-height: 18.5vw;
    }

    .m_my_contents_inner_left_box {
        position: relative;
        display: inline-block;
        width: 33.75vw;
        height: 100%;
        font-weight: bold;
        background-color: #f9f9f9;
        border-right: 1px solid #efefef;
        text-align: left;
    }

    .my_contents_inner_left_txt {
        padding-left: 6.25vw;
    }

    .m_my_password_box {
        position: absolute;
        display: inline-block;
        width: 47.3438vw;
        height: 8.75vw;
        background-color: #f3f3f3;
        margin-left: 6.25vw;
        margin-top: 5vw;
    }

    .m_my_password_input_box {
        position: absolute;
        display: inline-flex;
        justify-content: unset;
        align-items: center;
        width: 43.5938vw;
        height: 8.5938vw;
        padding: 0 0 0 1.875vw;
        z-index: 1;
    }

    .m_my_password_input_box input {
        color: #4e4e4e;
        width: 100%;
    }

    .m_result_msg {
        position: absolute;
        display: inline-block;
        width: 54.6875vw;
        height: 4.6875vw;
        font-size: 2.5000vw;
        color: #979797;
        padding: 7.0313vw 0 0 6.25vw;
    }

    .m_ok_title_box {
        position: relative;
        display: block;
        width: 100%;
        height: 14.5vw;
        font-size: 3.1250vw;
        font-weight: bold;
        background-color: #ffffff;
        border-bottom: 1px solid #efefef;
    }

    .m_ok_box {
        width: 100%;
        height: 45vw;
        border: 1px solid #dbdbdb;
        border-top: 2px solid #4e4e4e;
        background-color: #f9f9f9;
        text-align: center;
    }

    .m_ok_title {
        width: 100%;
        height: 17.1875vw;
        line-height: 6.2500vw;
        padding-top: 1.5313vw;
    }

    .m_ok_contents {
        display: block;
        width: 100%;
        height: 32.8125vw;
        font-weight: normal;
        font-size: 2.1875vw;
        color: #7b7b7b;
        line-height: 3.7500vw;
        padding-top: 12.9063vw;
    }

    .ok_event {
        position: absolute;
        margin-left: -25vw;
        margin-top: -18.5vw;
        background-size: 100%;
        width: 91vw
    }

    .m_policy_content {
        padding-bottom: 2.6vh;
        padding-top: 4.6875vw;
        width: 93.75vw;
        height: auto;
        margin: 0 auto;
    }

    .m_policy_content_box {
        display: block;
        width: 100%;
        min-height: 42.1875vw;
        border: 1px solid #dbdbdb;
        border-top: 2px solid #4e4e4e;
        background-color: #fff;
    }

    .m_agreement_contents {
        display: block;
        width: 84.0625vw;
        font-weight: normal;
        font-size: 2.1875vw;
        margin: 0 0 0 4.6875vw;
        line-height: 1.4;
        overflow: auto;
    }

    .m_agreement_contents_text {
        position: relative;
        width: auto;
        color: #7b7b7b;
        margin: 1.875vw 2.3438vw 2.3438vw 2.3438vw;
    }

    .m_notice_search_box {
        position: absolute;
        display: inline-block;
        width: 43.75vw;
        height: 5.4688vw;
        background-color: #fff;
        margin: -7vw 0 0 42.1875vw;
        border-top-left-radius: 0.7813vw;
        border-bottom-left-radius: 0.7813vw;

    }

    .m_notice_input_box {
        position: absolute;
        display: inline-block;
        width: 40vw;
        height: 5.7813vw;
        font-size: 2.1875vw;
        padding: 0 0 0 1.875vw;
        z-index: 1;
    }

    .m_notice_input_box input {
        color: #4e4e4e;
        font-size: 1.875vw;
        width: 100%;
        height: 100%;
    }

    .m_notice_search_box_btn {
        position: absolute;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 8.125vw;
        height: 5.4688vw;
        color: #ffffff;
        background-color: #4e4e4e;
        margin: 0 0 0 43.6vw;
        z-index: 1;
        border-top-right-radius: 0.7813vw;
        border-bottom-right-radius: 0.7813vw;
        font-size: 1.875vw;
    }

    .m_footer-container {
        height: 5vw;
        font-size: 2.5vw;
        margin-left: 3vw;
    }

    .m_game_swiper {
        height: 12.5000vw;
    }

    .footer-notice {

    }

    .footer-notice:hover {
        color: #0f69b4;
    }

    .info_top_section_inner_img {
        width: 7vw;
        height: 6vw;
        position: absolute;
        margin: 0 12vw;
    }

    .info_top_section_inner_img_2 {
        width: 7vw;
        height: 6vw;
        position: absolute;
        margin: 6vw 12vw;
    }

    .main_div_carosel {
        margin-top: -4px;
    }

    /*.BoxClass {*/
    /*    width: 34vw;*/
    /*    height: 34vw*/
    /*}*/

    /*.main_div_images img {*/
    /*    width: 26vw;*/
    /*    height: 26vw;*/
    /*    z-index: 2;*/
    /*}*/

    /*.main_div_images {*/
    /*    top: 15vw*/
    /*}*/

    .mobile_swiper video {
        height: 150vw;
    }

    .m_my_wallet_popup {
        display: block;
        position: fixed;
        z-index: 2000;
        padding-top: 30vh;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .m_my_wallet_popup_content {
        position: relative;
        z-index: 3000;
        justify-content: center;
        margin: auto;
        width: 80vw;
        height: 60vw;
        border-radius: 20px;

        border: 1vw solid #004279;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .m_message_pass_btn_web {
        color: white;
        width: 55vw;
        height: 15vw;
        margin-top: 4vw;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font-size: 5vw;
        font-weight: 400;
        background-image: url(../images/popup/pass-fail-popup.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    .m_message_pass_btn_web_div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5vw;
        width: 100%;
    }

    .m_message_pass_btn_web_s {
        color: white;
        width: 30vw;
        height: 15vw;
        margin-top: 4vw;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font-size: 5vw;
        font-weight: 400;
        background-image: url(../images/popup/blue_btn.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    .m_shop_popup {
        display: none;
        position: fixed;
        z-index: 2000;
        padding-top: 30vh;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.4);
    }

}


@media only screen and (orientation: landscape) {
  .m_menu_popup_box{
      font-size: 5vh;
  }

    .m_popup_contents_box div{
        margin-top: 1vh;
    }
}