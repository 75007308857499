.cashfree_popup {
    display: none;
    position: fixed;
    z-index: 2000;
    padding-top: 9.1%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
}


.freecharge_popup {
    display: none;
    position: fixed;
    z-index: 2000;
    padding-top: 9.1%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
}

.popup-container {
    background-color: #96CAF7;
    border: 7px solid #004279;
    border-radius: 40px;
    height: 580px;
    max-width: 900px;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 12px;
}

.popup-heading {
    position: absolute;
    top: -50px;
    z-index: 10;
    display: flex;
    width: 100%;
    justify-content: center;
}

.close-icon {
    right: -20px;
    top: 20px;
    position: absolute;
    cursor: pointer;
}

.inner-container {
    background: white;
    border: 7px solid #88C7FB;
    border-radius: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.currency-switch {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.currency-option {
    width: 250px;
    height: 50px;
    border: 1px solid #046BC1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.currency-option:first-child {
    border-radius: 12px 0 0 12px;
}

.currency-option:last-child {
    border-radius: 0 12px 12px 0;
}

.currency-option:hover {
    background-color: #E7F4FF
}

.tablinks2 {
    border-radius: 8px;
    cursor: pointer;
}

.contentsInnerBox2 {
    margin: 0;
    border: 0;
    border-radius: 0;
}

.CashBox_RightInputBox2 {
    width: 240px;
    height: 50px;
    background-color: #ffffff;
    border: 1px solid #046BC1;
    color: #004985;
    font-size: 20px;
    font-weight: bold;
    margin-left: 8px;
    text-align: right;
    line-height: 45px;
    border-radius: 8px;
}

.InputMONEY2,
.OutputMONEY2 {
    height: 50px;
}

.loading {
    padding-top: 30%;
}

.contents-inner-box {
    position: relative;
    width: 800px;
    height: auto;
    z-index: 1;
}

.tablinks2 {
    display: inline-block;
    width: 240px;
    height: 60px;
    color: #004985;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    line-height: 60px;
    border: 1px solid #046BC1;
    margin-top: 30px;
}

.tablinks2:First-Child {
    margin-left: 30px;
}

.tablinks2.active {
    background-color: #004985;
    color: white;
}

.label-box {
    border-radius: 8px;
    border: 1px solid #046BC1;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 400;
    color: #414141;
}

.money-box-div {
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

.tab-content {
    margin-top: 30px;
}

.current-tab-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.money-box {
    border: 1px solid #046BC1;
    padding: 0 10px;
    width: 240px;
    height: 50px;
    background-color: #C1DDF6;
    border-radius: 8px;
    font-size: 16px;
    color: #004985;
    display: flex;
    justify-content: end;
    align-items: center;
}

.web-popup-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.web-popup-btn {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 24px;
    width: 400px;
    height: 60px;
    background-image: url(../images/popup-btn.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    color: white;
    align-items: center;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.web-popup-btn:hover {
    background-image: url("../images/popup/btn-hover.svg");
}


.web-popup-btn-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
}

.InputMONEY {
    width: 200px;
    height: 45px;
    font-size: 25px;
    color: #004985;
    text-align: right;
    margin-left: -20px;
}

.CashBoxRightContents {
    display: flex;
    gap: 10px;
    margin-top: 30px;
}


.history-container-1 {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.history-container-2 {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.history-item {
    display: flex;
    gap: 8px;
}

.history-item-title {
    border-radius: 8px;
    border: 1px solid #046BC1;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 400;
    color: #414141;
}

.history-item-content {
    border: 1px solid #046BC1;
    padding: 0px 10px;
    width: 240px;
    height: 50px;
    background-color: #C1DDF6;
    border-radius: 8px;
    font-size: 16px;
    color: #004985;
    display: flex;
    justify-content: end;
    align-items: center;
}

.prev-next-buttons {
    display: flex;
    gap: 8px;
    height: 50px;
}

.prev-next-button {
    border-radius: 8px;
    border: 1px solid #046BC1;
    width: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 400;
    color: #414141;
    cursor: pointer;
}

.prev-next-button:hover {
  background: #E7F4FF;
}

.arrow-icon-left {
    width: 10px;
    height: 13px;
    padding-right: 10px;
}

.arrow-icon-right {
    width: 10px;
    height: 13px;
    padding-left: 10px;
}

.tablinks2.inactive:hover {
    background-color: #E7F4FF;
}

.web-popup-inactive:hover {
    background: #E7F4FF;
}




#message-popup {
  display: none;
}

.message-main-pop {
    display: none;
    width: 100%;
    height: 100%;
    border: none;
    position: fixed;
    z-index: 3000;
    margin-top: 9rem;
    justify-content: center;
}

.message-popup-container {
  border-radius: 20px;
  border: 4px solid #004279;
  background: #fff;
  width: 319px;
  height: 248px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message-popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

#message_popup_pass_string, #message_popup_fail_string {
  margin-top: 30px;
  color: #000;
  font-size: 16px;
}

.message_popup_container_div {
    justify-content: center;
    align-items: center;
}

.message-pass-btn-web {
    color: white;
    height: 50px;
    width: 219px;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    background-image: url(../images/popup/pass-fail-popup.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.message-pass-btn-web:hover {
  background-image: url("../images/popup/pass-fail-popup-hover.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}


.free_charge-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: #004985;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
}

.free_charge-container img {
  height: 12px;
  width: 20px;
}

.free_charge-container p {
  color: #004985;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}



.free_charge_limit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #004985;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.free_charge_limit img {
  height: 12px;
  width: 20px;
}

.free_charge_limit p {
  display: flex;
  justify-content: center;
  color: #414141;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.free_charge_limit em {
  color: #414141;
  display: flex;
  align-items: center;
}

.wallet-btn-web {
    color: white;
    height: 50px;
    width: 130px;
    margin-top: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    background-image: url(../images/popup/blue_btn.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.wallet-btn-web:hover {
  background-image: url("../images/popup/blue_btn_hover.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}