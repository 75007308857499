/* TOP */
.other-game-section {
    display: block;
    width: 100%;
    height: 550px;
    background-position: center;
}

.other-game-info-area {
    position: relative;
    display: block;
    width: auto;
    height: 642px;
}

.center-warp-other-game {
    height: 100%;
    margin: 0 auto;
}

.center-warp-img-other-game {
    display: none;
    position: absolute;
    height: 434px;
    text-align: center;
    margin: -48px auto
}

.other-game-title-text-img {
    display: flex;
    justify-content: center;
}

.title-text-other-game {
    width: 130px;
    height: 48px;
    color: #ffffff;
    font-size: 32px;
    padding: 130px 0 0 0;
    margin: 0 auto 40px auto;
    border-bottom: 2px solid #acacac;
    text-align: center;
}

.info-text-other-game {
    position: relative;
    display: inline-block;
    width: 173px;
    height: 45px;
    font-size: 20px;
    color: #8f8f8f;
    padding-right: 0;
}

.explain-area-other-game {
    position: relative;
    display: block;
    width: auto;
    height: 434px;
    background-color: #656565;
}

.explain-text-other-game{
    text-align: center;
    font-size: 20px;
    color: #acacac;
}

.point-area-other-game {
    position: relative;
    display: block;
    width: auto;
    height: 850px;
}

.PointBackgoround {
    width: 1220px;
    height: 490px;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
    background-image: url('../images/battlesniper_img/battlesniper_point.jpg');
}

.point-box {
    position: absolute;
    display: inline-block;
    width: 407px;
    height: 244px;
    text-align: center;
}

.point-b-text {
    width: 300px;
    height: 150px;
    font-size: 32px;
    color: #ffffff;
    margin: 0 auto;
}

.point-s-text {
    width: 300px;
    height: 150px;
    font-size: 20px;
    color: #4e4e4e;
    margin: 0 auto;
    line-height: 1.5;
}

.PointBackgoround {
    width: 1220px;
    height: 490px;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
}

.PointBox {
    position: absolute;
    display: inline-block;
    width: 407px;
    height: 244px;
    font-family: ap;
    text-align: center;
}

.PointSText {
    width: 300px;
    height: 150px;
    font-size: 20px;
    color: #4e4e4e;
    margin: 0 auto;
    line-height: 1.5;
}

.PointBText {
    width: 300px;
    height: 150px;
    font-size: 32px;
    color: #ffffff;
    margin: 0 auto;
}


.PointArea {
    position: relative;
    display: block;
    width: auto;
    height: 850px;
}

.TitleText {
    width: 9.6%;
    height: 48px;
    color: #ffffff;
    font-size: 32px;
    padding: 130px 0 0 0;
    margin: 0 auto 40px auto;
    border-bottom: 2px solid #acacac;
}


@media only screen and (max-width: 1280px) {
    .center-warp-img-other-game {
        margin: -7px auto
      }

    .center-warp-img2-other-game{
        width: 1280px;
      }
}