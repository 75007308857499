

.shop-tab {
    padding-left: 15px;
    height: auto;
    display: flex;
}

.shop-top-my-section-title {
    position: relative;
    display: inline-block;
    width: auto;
    height: auto;
    font-size: 34px;
    font-weight: bold;
    color: #4e4e4e;
    padding: 0 30px 0 0;
}

.shop-top-my-section-tit {
    position: relative;
    display: inline-block;
    width: auto;
    height: 30px;
    font-size: 19px;
    font-weight: normal;
    color: #7b7b7b;
    cursor: pointer;
    padding: 12px 0 0 0;
    margin: 0 30px 0 30px;
    z-index: 2;
}


.subMainDepth {
    position: absolute;
    width: 400px;
    font-size: 12px;
    color: #7b7b7b;
    font-weight: normal;
    padding: 0 0 0 0;
    margin: -23px 0 0 570px;
    text-align: right
}

.Buy_section {
    position: relative;
    min-height: 351px;
    height: 724px;
    border: 1px solid #e2e2e2;
    background-color: #ffffff;
}

.Buy_contents {
    width: 70%;
    height: 644px;
    background-color: #ffffff;
    margin: 40px auto;
}

.Buy_AvartaImg {
    position: absolute;
    width: 98px;
    height: 145px;
    background-color: #f3f3f3;
    padding: 80px;
    margin: 100px 0 0 -98px;
    border-radius: 10px;
}

.Buy_contentsInner {
    display: inline-block;
    width: 70%;
    height: 100%;
    margin-left: 210px;
}

.Buy_TitleBox {
    width: 100%;
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding-top: 50px;
    border-bottom: 1px solid #dbdbdb;
}

.Buy_ContentsBox2 {
    width: 100%;
    height: 100px;
    font-weight: bold;
    background: #0f69b4;
}

.Buy_ContentsBox {
    width: 100%;
    height: 50px;
    font-weight: bold;
    border-bottom: 1px solid #dbdbdb;
}

.Buy_ContentsTitleBox {
    display: inline-block;
    width: 306px;
    height: 100%;
    margin-left: 40px;
    font-size: 20px;
    margin-top: 10px;
}

.buy_contents_box_2 {
    height: 40px;
    position: absolute;
    display: inline-block;
    background-color: #ededed;
    margin-top: 5px
}

.Buy_ContentsTextBox {
    position: absolute;
    display: inline-block;
    width: auto;
    height: 50px;
    font-size: 16px;
    font-weight: normal;
}

.Countnumber {
    position: absolute;
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #ededed;
    font-size: 16px;
    text-align: center;
    margin-top: 5px;
}

.Countnumber input {
    width: 100%;
    color: #4e4e4e;
    font-size: 16px;
    text-align: center;
}

#numberUpDown {
    margin-top: 6px;
}

.CountnumberUPDOWNbox {
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 40px;
    margin-top: 4px;
    margin-left: 40px;
    border: 1px solid #ededed;
}

.CountnumberUPImg {
    position: absolute;
    width: 20px;
    height: 20px;
}

.CountnumberDOWNImg {
    position: absolute;
    width: 20px;
    height: 20px;
    margin-top: 20px;
}

.BuydropBox_select {
    display: inline-block;
    position: relative;
    width: 140px;
    height: 100%;
    font-size: 14px;
    color: #333;
    border: none;
    z-index: 1;
    background: url(../images/servicecenter_img/Icon-dropbox.png) no-repeat 95% 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px 14px;
}

.BuydropBox_select option{
    background: #ededed;
}

.BuyMoneyTextBox {
    width: 100%;
    height: 100px;
    background-color: #f3f3f3;
}

.BuyTotalMoneyBox {
    width: 100%;
    height: 50px;
    font-size: 16px;
    text-align: right;
}

.quit_oknoBox_button {
    display: block;
    width: 100%;
    height: 42px;
    padding-top: 40px;
    text-align: center;
}

.BuyMoneyTextBoxInner {
    position: absolute;
    display: block;
    width: 489px;
    height: 40px;
    color: #15a3e1;
    font-size: 16px;
    line-height: 2.5;
}

.BuyMoneyTextBoxInner2 {
    position: absolute;
    display: inline-block;
    text-align: center;
    width: 100%;
}


.quit_oknoBox_button {
    display: block;
    width: 100%;
    height: 42px;
    padding-top: 40px;
    text-align: center;
}


.quit_okno_button {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 42px;
    font-size: 16px;
    color: #ffffff;
    background-color: #15a3e1;
    text-align: left;
    padding-left: 14px;
    padding-bottom: 2px;
    margin-right: 10px;
    border-radius: 5px;
}

.quit_okno_button_img {
    position: absolute;
    margin: -18px 0 0 95px;
}

.ShopGameCenterwarpMain {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    padding-bottom: 80px
}



/* 탭2 아바타 부분 */
.tabA {
  position: relative;
  text-align: left;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 60px;
  border-bottom: 1px solid #bcbcbc;
}
.tablinksA {
  margin-top: 12px;
  margin-left: 107px;
  width: 97px;
  height: 46px;
  border: none;
  outline: none;
  font-size: 16px;
  color: #fffff;
}
.tablinksA:nth-child(1) {
  margin-left: 43px;
}
.tablinksA.active {
  font-size: 16px;
  border-bottom: 2px solid #4e4e4e;
  border-bottom-width: 20%;
}
.tablinksA:hover {
  font-size: 16px;
  border-bottom: 2px solid #4e4e4e;
  border-bottom-width: 20%;
}

.tabAcenterBar {
  position: overlay;
  display: block;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #eeeeee;
  padding-top: 281px;
}

/* 탭3 아이템 부분 */
.tabB {
  position: relative;
  text-align: left;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 60px;
  border-bottom: 1px solid #bcbcbc;
}
.tablinksB {
  margin-top: 12px;
  margin-left: 107px;
  width: 98px;
  height: 46px;
  border: none;
  outline: none;
  font-size: 16px;
  color: #fffff;
}
.tablinksB:nth-child(1) {
  margin-left: 43px;
}
.tablinksB.active {
  font-size: 16px;
  border-bottom: 2px solid #4e4e4e;
  border-bottom-width: 20%;
}


.shop_section_2 {
    position: relative;
    min-height: 351px;
    height: auto;
    border: 1px solid #e2e2e2;
    background-color: #fff;
}


.shop_card_item {
    position: relative;
    width: 165px;
    height: 260px;
}

.shop_col_type1 {
    margin-bottom: 30px;
    background-color: #ffffff;
    text-align: center;
}

.game_shop_col_type {
    margin-bottom: 40px;
    background-color: #ffffff;
    text-align: center;
}

.shop-tab-content {
    display: grid;
    grid-template-columns: repeat(5, 0fr);
    gap: 43px;
    margin-top: 40px;
}

.ShopGameCenterWarpInner {
    width: 1000px;
    margin: 0;
}

.ShopGameCenterwarp {
    display: flex;
    width: 1400px;
    margin: 0 auto;
    gap: 35px
}

.shop_card_tit {
    overflow: hidden;
    display: block;
    margin: 13px 0 10px 0;
    font-size: 20px;
    font-weight: bold;
    color: #212121;
    line-height: 20px;
    text-overflow: ellipsis;
}

.shop_card_txt {
    display: block;
    margin: 13px 0 0 0px;
    font-size: 12px;
    color: #8d8d8d;
    line-height: 15px;
}

.shop_card_price {
    display: flex;
    font-size: 15px;
    justify-content: center;
    color: #444344;
    margin: 5px 0 3px 0;
}

.shop_tab_center_bar {
  position: absolute;
  display: block;
  width: 100%;
  height: 30.5px;
  border-bottom: 1px solid #eeeeee;
}

.shop_message_popup {
    display: none;
    position: fixed;
    z-index: 2000;
    padding-top: 15%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
}