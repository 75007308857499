@charset "euc-kr";
/*???? �???? �??�??*/
@font-face {
  font-family: 'ng';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/NanumGothic-Regular.eot');
  src: url('../fonts/NanumGothic-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/NanumGothic-Regular.woff2') format('woff2'),
    url('../fonts/NanumGothic-Regular.woff') format('woff'),
    url('../fonts/NanumGothic-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ng';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/NanumGothic-Bold.eot');
  src: url('../fonts/NanumGothic-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NanumGothic-Bold.woff2') format('woff2'),
    url('../fonts/NanumGothic-Bold.woff') format('woff'),
    url('../fonts/NanumGothic-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'ng';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/NanumGothic-ExtraBold.eot');
  src: url('../fonts/NanumGothic-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/NanumGothic-ExtraBold.woff2') format('woff2'),
    url('../fonts/NanumGothic-ExtraBold.woff') format('woff'),
    url('../fonts/NanumGothic-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Apple SD Gothic Neo';
  font-weight: normal;
  src: url(../fonts/AppleSDGothicNeoB.ttf), url(../fonts/AppleSDGothicNeoM.ttf);
}
@font-face {
  font-family: 'ap';
  font-weight: normal;
  src: url(../fonts/AppleSDGothicNeoR.ttf), url(../fonts/AppleSDGothicNeoL.ttf);
}
@font-face {
  font-family: 'Noto Sans KR Thin';
  font-style: normal;
  src: url(../fonts/NotoSansKR-Thin.woff2) format('woff2'),
    url(../fonts/NotoSansKR-Thin.woff) format('woff'),
    url(../fonts/NotoSansKR-Thin.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR Light';
  font-style: normal;
  src: url(../fonts/NotoSansKR-Light.woff2) format('woff2'),
    url(../fonts/NotoSansKR-Light.woff) format('woff'),
    url(../fonts/NotoSansKR-Light.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  src: url(../fonts/NotoSansKR-Regular.woff2) format('woff2'),
    url(../fonts/NotoSansKR-Regular.woff) format('woff'),
    url(../fonts/NotoSansKR-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR Medium';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/NotoSansKR-Medium.woff2) format('woff2'),
    url(../fonts/NotoSansKR-Medium.woff) format('woff'),
    url(../fonts/NotoSansKR-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR Bold';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/NotoSansKR-Bold.woff2) format('woff2'),
    url(../fonts/NotoSansKR-Bold.woff) format('woff'),
    url(../fonts/NotoSansKR-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR Black';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/NotoSansKR-Black.woff2) format('woff2'),
    url(../fonts/NotoSansKR-Black.woff) format('woff'),
    url(../fonts/NotoSansKR-Black.otf) format('opentype');
}

/* reset */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
textarea,
button {
  margin: 0;
  padding: 0;
}
html{
  scroll-behavior: smooth;
}

/* IE9 ?��??�?? ???? css */
.placeholder {
  font-size: 11px;
  color: #c1c1c1;
}
/* IE10 ?��???? ???? css */
input::placeholder {
  color: #c1c1c1;
  font-size: 11px;
  opacity: 1;
} /* ???��?��???��???? �????�?? ???��?? ?????? 방�???�?? ???? css */
input::-webkit-input-placeholder {
  color: #c1c1c1;
  font-size: 11px;
} /* IE */
input:-ms-input-placeholder {
  color: #c1c1c1;
  font-size: 11px;
} /* Firefox */
input:-mos-input-placeholder {
  color: #c1c1c1;
  font-size: 11px;
}

html {
  overflow-y: auto;
  overflow-x: hidden;
} /* ??�???? 배�?? �???? ???��???? */
body {
  /*overflow: hidden;*/
  position: relative;
  min-width: 1400px;
  /*background-color: #333333;*/
  background-color: #FFFFFF;
} /* ??�???? 배�?? �???? ???��???? font-family:'Apple SD Gothic Neo';*/


body,
input,
textarea,
select,
button,
table {
  font-family: 'Noto Sans KR', 'ng', 'NanumGothic', sans-serif;
  font-size: 12px;
  color: #333;
}
/*body,input,textarea,select,button,table{font-family:'????�????','NanumGothic',ng,'????',Dotum,'Apple SD Gothic Neo',sans-serif;font-size:12px;color:#333} */
fieldset,
img,
button {
  border: 0 none;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}
address,
caption {
  font-style: normal;
  font-weight: normal;
}
em {
  font-style: normal;
}
dl,
ul,
ol,
li {
  list-style: none;
}

a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
  overflow: visible;
  border: 0;
  background: transparent;
  box-sizing: content-box;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input[type='text'],
input[type='password'] {
  border: 0;
  background: transparent;
  vertical-align: top;
  outline: none;
}
input[type='checkbox'],
input[type='radio'] {
  border: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  vertical-align: top;
}
input[type='text']::-ms-clear {
  display: none;
}
input {
  -webkit-appearance: none;
  appearance: none;
}
hr {
  display: none;
}
*:focus {
  outline: none;
}
.blind,
legend {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  font-size: 1px;
  line-height: 100px;
  white-space: nowrap;
}

/* layout */
.Topwrap {
  min-width: 60px;

 
}

.header-wrap {
  position: sticky;
  width: auto;
  /* height: 600px; */
  top: 0 !important;
  /* background-color: white; */
  background-color: transparent;
  z-index: 1000;
}



.swiper-button-next {
  background-image: url('../images/main_img/next_slider.svg');
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  padding: 8px 16px;
  border-radius: 100%;
}

.swiper-button-prev {
  background-image: url('../images/main_img/prev_slider.svg');
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  padding: 8px 16px;
  border-radius: 100%;
}

/* .swiper-button-next:after .swiper-button-prev:after {
  content: '';
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: '';
} */

.swiper-button-next::after,
.swiper-button-prev::after {
  content: '' !important;
}

.inner {
  display: flex;
  padding: 15px 65px;
  gap: 20px;
}

.PopupAllgameBox {
  position: absolute;
  width: 998px;
  height: 210px;
  font-size: 14px;
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  border-top: 2px solid #4e4e4e;
  background-color: #ffffff;
}
.AllgameBox {
  display: block;
  width: 998px;
  height: 210px;
  border-bottom: 1px solid #dbdbdb;
}
.AllgameBox_innerBox {
  position: relative;
  display: inline-block;
  width: 127px;
  height: 100%;
  padding-right: 35px;
}

.AllgameBox_innerTitletext {
  position: absolute;
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  padding: 91px 0 0 35px;
}

.AllgameBox_innertext {
  position: absolute;
  font-size: 14px;
  font-weight: normal;
  padding: 25px 0 0 27px;
}

.AllgameBox_innertext a:hover {
  color: #15a3e1;
}

.AllgameBox_innertext div {
  cursor: pointer;
}

.AllgameBox_innertext div:hover {
  color: #15a3e1;
}

.all_game_box_inner_text_2{
  font-weight: bold;
  color: #4e4e4e;
}

.all_game_box_title {
  font-weight: bold;
  cursor: unset !important;
  margin-bottom: 5px;
}

.all_game_box_title:hover {
  color: #000 !important;
}

.AllgameBox_gameimg {
  position: absolute;
  margin: 146px 0 0 29px;
}
.PopupServicecenterBox {
  position: relative;
  width: 998px;
  height: 134px;
  font-size: 14px;
  border: 1px solid #dbdbdb;
  border-top: 1px solid #efefef;
  background-color: #f9f9f9;
  margin: -5px 0 0 -1px;
}
.PopupClosepopupButton {
  position: absolute;
  display: inline-block;
  margin: 12px 0 0 11px;
}

.contentsinterval {
  width: auto;
  height: 6px;
}

.contentsinterval:hover {
  fill: #15a3e1;
}

.menu_right {
  flex-direction: column;
  display: flex;
  /* width: 100%; */
}

.menu_title_text {
  width: fit-content;
}

.menu_title_text img {
  margin: auto;
  display: block;
}

/* header */
.header {
  position: sticky;
  z-index: 200;
  /* height: 60px; */
  top: -10%;
  /* opacity: 0.2; */
  background: rgb(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  padding: 21px 20px;
  align-items: center;
  color: #000;
}


#center_navigation1{
  /* display: contents; */
  display: flex;
  width: 1400px;
  margin: auto;
}


.sticky {
  z-index: 1100 !important;
  background-color: #fff !important ;
  display: flex;
  justify-content: center;
  padding: 21px 20px;
  align-items: center;
  color: #000;
  border: none !important;
  width: auto;
}

.sticky + .content {
  padding-top: 250px;
}

.sticky button {
  color: #000;
}

.sticky .header_menu_opt #divLogin #divBeforeLogin .lg_btm span a{
  color: #000;
} 

.sticky .header_menu_opt #divLogin #divBeforeLogin .lg_btm span a:hover{
  color: #15a3e1;
}

.game_header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  /* width: 100% !important; */
  z-index: 1100 !important;
  background-color: #fff !important ;
  display: flex;
  justify-content: center;
  padding: 21px 20px;
  align-items: center;
  color: #000;
  border: none !important;
}

.game_header + .content {
  padding-top: 250px;
}

.game_header button {
  color: #000;
}

.game_header .header_menu_opt #divLogin #divBeforeLogin .lg_btm span a{
  color: #000;
}

.game_header .header_menu_opt #divLogin #divBeforeLogin .lg_btm span a:hover{
  color: #15a3e1;
}

.game_header .header_menu svg path {
  fill: #000;
}

/* .sticky .header_menu_opt #divLogin #divBeforeLogin .lg_btm span button a{
  color: #fff !important;
}  */


.header:hover {
  background: rgba(255, 255, 255, 1);
  color: #000;
}

#headerclass:hover {
  background: rgba(255, 255, 255, 1);
  color: #000;
}

.header_side_l {
  display: inline-block;
  *display: inline;
  vertical-align: top;
  *zoom: 1;
}
.header_tit {
  /* display: inline-block; */
  /* *display: inline; */
  /* margin: 0px 0 0 -1px;
  vertical-align: top;
  *zoom: 1; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.header_menu_opt {
  display: flex;
  justify-content: space-between;
  width: auto;
  align-items: flex-end;
  margin-top: 20px;
  /* padding: 0px 0px 0px 30px; */
  width: 100%;
}

.header_menu:hover span {
  color: #15a3e1;
}

.header_menu:hover .header_menu svg path {
  fill: #15a3e1;
}

.header:hover button,
.header:hover a {
  color: #000;
}

.header_menu {
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 6px;
}


/* .header_menu span {
  padding-top: 5px;
} */


.header:hover .header_menu svg path {
  fill: #000;
}

.sticky .header_menu svg path {
  fill: #000;
}


.header:hover .t1 span {
  color: #fff !important;
}

.service_menu:hover .header_menu svg path {
  fill: #15a3e1;
}

.lg_btm {
  display: flex;
  justify-content: center;
  align-items: end;
}

.service {
  margin: auto;
  font-size: 0;
}
.service button:hover {
  color: #15a3e1;
}
.service_menu {
  color: white;
  font-size: 16px;
  /*font-family: JejuGothic;*/
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
  /* margin-top: 30px;
  display: inline-block;
  letter-spacing: -1px;
  margin-right: 20px;
  padding: 0px 0px;
  height: 55px; */
  gap: 20px;
}

.service_menu:hover span {
  color: #0f69b4;
}



.sm_logo {
  position: absolute;
  display: inline-block;
  width: 100%;
  margin: 0 0 0 41%;
}

.logo_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.t1 a {
  color: white;
  font-size: 16px;
  /*font-family: JejuGothic;*/
  font-weight: 600;
  line-height: 24px;
  padding: 5px;
}

/* .t1 a:hover {
  color: #0f69b4;
  font-size: 16px;
  font-family: JejuGothic;
  font-weight: 600;
  line-height: 24px;
  padding: 5px;
} */


.login_button a button {
  background-color: #0f69b4;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  border-radius: 12px;
  padding: 6px 30px;
  margin-top: 5px;
  color:#fff;
  
}

#headerclass .login_button a button {
  background-color: #0f69b4;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  border-radius: 6px;
  padding: 6px 30px;
  margin-top: 5px;
  color:#fff;
}


#headerclass .login_button a button:hover {
  background-color: transparent;
  outline: 1px solid #0f69b4;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  border-radius: 6px;
  padding: 6px 30px;
  margin-top: 5px;
  color:#15a3e1;
  
}



.t1 button {
  background-color: #0f69b4;
  border-radius: 12px;
  padding: 6px 30px;
  margin-top: 5px;
}

.t1 button:hover{
  background-color: transparent;
  outline: 1px solid #0f69b4 ;
  padding: 6px 30px;
  color: #17dd42;
  border-radius: 12px;
}

/* .t1 button a:hover{
  color: #0f69b4 !important;
 
} */


.sticky .header_menu_opt #divLogin #divBeforeLogin .lg_btm span button a:hover{
  
  background-color: transparent;
  color: #e31414;
}


.sticky .header_menu_opt #divLogin #divBeforeLogin .lg_btm span button a:hover {
  color: #0f69b4;
}

.info_txt_bar1 {
  display: inline-block;
  width: 2px;
  height: 18px;
  background: #0f69b4;
  vertical-align: middle;
  /* margin: -2px 10px 0; */
  /* margin: -3px 10px 0; */
  margin: 0px 10px 2px 10px;
}

/* header �??그�?? */
.login_set .checkbox-applied {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 12px;
  height: 12px;
  border: 1px solid #e2e2e2;
  border-top-color: #c9cacb;
  vertical-align: middle;
}
#divLogin {
  /* position: relative;
  left: 1100px;
  height: 85px;
  width: 400px;
  margin-top: -40px; */
}

#divLogin a:hover {
  color: #15a3e1 !important;
}

/* PC -> 모�??? */
.GOMobileBox {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 212px;
  background-color: #333333;
  z-index: 100;
  margin: 0 auto;
}
.GOMobileButton {
  position: relative;
  width: 1352px;
  height: 120px;
  background-color: #ffffff;
  text-align: center;
  margin: 45px auto;
  font-size: 40px;
  line-height: 120px;
  z-index: 101;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.GOMobileButtonImg {
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 32px;
  margin: 43px 0 0 420px;
}
.GOMobileButtonImg img {
  width: 100%;
  height: auto;
}

/*�???? ?��??? */
.section_tit_box {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 111px;
  margin: 0% auto;
  background-color: #f5f5f5;
  border-bottom: 1px solid #bcbcbc;
}
.section_tit_box_inner {
  position: relative;
  width: 1400px;
  height: 37px;
  top: 69px;
  left: -90px;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  font-family: Noto Sans KR Medium;
}
.section_tit_box_inner .section_tit {
  font-size: 26px;
}
.section_tit_underLbox {
  position: relative;
  display: overlay;
  width: 1400px;
  height: 5px;
  top: -7px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
}
.section_tit_box_underL {
  position: absolute;
  display: overlay;
  height: 1px;
  border-bottom: 4px solid #15a3e1;
}
.section_tit_box_underL2 {
  position: absolute;
  display: overlay;
  height: 1px;
  border-bottom: 4px solid #4e4e4e;
}

.box_underL {
  position: relative;
  display: inline-block;
  height: 41px;
  color: #4e4e4e;
  font-size: 25px;
  border-bottom: 4px solid #15a3e1;
}
.box_underR {
  position: relative;
  display: inline-block;
  height: 41px;
  color: #4e4e4e;
  font-size: 25px;
  border-bottom: 4px solid #4e4e4e;
  margin-left: -5px;
}

/* *_ok */
.ok_Box {
  width: 100%;
  height: 288;
  border: 1px solid #dbdbdb;
  border-top: 2px solid #4e4e4e;
  background-color: #f9f9f9;
  text-align: center;
}
.ok_TitleBox {
  position: relative;
  display: block;
  width: 100%;
  height: 110px;
  font-size: 20px;
  font-weight: bold;
  background-color: #ffffff;
  border-bottom: 1px solid #efefef;
}
.ok_Title {
  width: 100%;
  height: 110px;
  line-height: 40px;
  padding-top: 20px;
}
.ok_contents {
  display: block;
  width: 100%;
  height: 130px;
  font-weight: normal;
  font-size: 14px;
  color: #7b7b7b;
  line-height: 1.8;
  padding-top: 100px;
}
.ok_img {
  position: absolute;
  margin-left: -160px;
  margin-top: -125px;
}

/* �???? */
.oknoBox_button {
  display: block;
  width: 100%;
  height: 42px;
  padding-top: 40px;
  text-align: center;
}
.okno_button {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #ffffff;
  background-color: #15a3e1;
  padding: 9px 46px 9px 14px;
  margin-top: 6px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.okno_button_img {
  position: absolute;
  margin: -18px 0 0 53px;
}

/* Right */
.Rightwarp {
  position: absolute;
  display: inline-block;
  width: 360px;
  height: auto;
  margin: 0 0 0 1038px;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
} /* height:835px*/
.Rightwarp_myinfoBox {
  display: block;
  width: 100%;
  height: 200px;
}

.myinfo_imgBox {
  position: absolute;
  display: inline-block;
  width: 150px;
  height: 100px;
  margin: 0 auto;
  padding: 25px 0 0 40px;
}

.myinfo_textBox {
  position: absolute;
  display: inline-block;
  /*width: 140px;*/
  height: 100px;
  margin: 0 auto;
  padding: 40px 0 0 179px;
  font-size: 16px;
}

.myinfo_textBox a:hover {
  color: #15a3e1;
}

.myinfo_textBox div {
  cursor: pointer;
}

.myinfo_textBox div:hover {
  color: #15a3e1;
}


.mylostMoney {
  width: 324px;
  height: 224px;
  margin: 0 auto;
  text-align: center;
}
.mylostMoney_Title {
  width: 100%;
  height: 26px;
  color: #c10000;
  font-size: 16px;
  font-family: 'Noto Sans KR';
  padding: 20px 0;
}
.mylostMoney_GrayBox {
  width: 100%;
  height: 59px;
  font-size: 12px;
  color: #c10000;
  background-color: #f3f3f3;
  line-height: 3;
  border: 1px solid #e7e7e7;
}
.mylostMoney_WhiteBox {
  width: 100%;
  height: 37px;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  border-top: none;
  line-height: 37px;
}
.mylostMoney_ContentsBox {
  text-align: left;
  padding-top: 7px;
}

.Rightwarp_lastjoinBox {
  display: block;
  width: 100%;
  height: 55px;
  background-color: #f3f3f3;
  text-align: center;
  line-height: 55px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}
/* 201130 �?? �???????��??? �??�??, 무�?충�?? ?��??�???? �??�?? */
.Rightwarp_cashORfree {
  display: block;
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  text-align: center;
  line-height: 55px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}
.Rightwarp_box_button {
  display: block;
  width: 100%;
  height: 42px;
  padding-top: 18px;
  margin-left: 20px;
  text-align: center;
}
.cashfree_button {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 40px;
  font-size: 16px;
  color: #ffffff;
  background-color: #15a3e1;
  text-align: center;
  padding-bottom: 2px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.Rightwarp_moneyinfoBox {
  display: block;
  width: 100%;
  height: 170px;
}
.moneyinfo_imgBox {
  position: absolute;
  display: inline-block;
  width: auto;
  height: auto;
  margin: 0 auto;
  padding: 20px 0 0 20px;
}
.moneyinfo_textBox {
  position: absolute;
  display: inline-block;
  width: auto;
  height: 100px;
  margin: 0 auto;
  padding: 24px 0 0 100px;
  font-size: 12px;
}

.moneyinfo_popup {
  position: absolute;
  width: 350px;
  height: 200px;
  z-index: 1;
  margin: 80px auto;
  background-color: #fff;
  border: 5px solid #4e4e4e;
}
.popup_titleBox {
  position: relative;
  display: block;
  width: 100%;
  height: 70px;
  background-color: #4e4e4e;
  font-size: 20px;
  color: #fff;
  text-align: center;
  line-height: 70px;
}
.popup_moneyBox {
  position: relative;
  display: block;
  width: 100%;
  height: 65px;
  font-size: 16px;
  line-height: 3;
}
.popup_moneyBox:nth-child(2) {
  border-bottom: 1px solid #e7e7e7;
}
.moneyBox_innertext {
  position: absolute;
  display: inline-block;
  width: auto;
  height: 20px;
  margin: 15px 0 20px 3px;
  font-size: 12px;
}
.moneyBox_innertext:nth-child(1) {
  margin: 6px 0 20px 35px;
  font-size: 16px;
}

.Rightwarp_SMmoneyBox {
  display: block;
  width: 100%;
  height: 85px;
  text-align: left;
  line-height: 75px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}

.SMmoney_chargebutton {
  color: #ffffff;
  background-color: #c10000;
  padding: 5px 20px;
  margin: 25px 0 0 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.Rightwarp_noticeBox {
  display: block;
  width: 100%;
  height: 285px;
  text-align: left;
  background-color: #f3f3f3;
}

.notice_text_box_see_more {
  font-size: 14px;
  padding-left: 130px;
}

.notice_text_box_see_more:hover {
  color: #15a3e1;
  cursor: pointer;
}

.notice_textBox {
  position: relative;
  display: inline-block;
  width: auto;
  height: 100px;
  line-height: 16px;
  margin: 0 auto;
  padding: 40px 0 0 40px;
  font-size: 12px;
}

.notice_text_box_date {
  font-size: 10px;
  color: #8c8c8c;
}

.notice_textBox a:hover {
  color: #15a3e1;
}

.notice_text_box_content {
  cursor: pointer;
}

.notice_text_box_content:hover{
  color: #15a3e1;
}

.notice_text_box_content:hover .notice_text_box_date{
  color: #15a3e1;
}

.RightwarpsecurityBox {
  display: block;
  width: 100%;
  height: 70px;
  text-align: left;
  line-height: 60px;
  border-top: 1px solid #e2e2e2;
}
.RightwarpsecurityBox a:hover {
  color: #15a3e1;
}

/* ?��?��? �???��?? ?��??? ???? */
.scale {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease-in-out; /*�?????��?? 모�???? ???? �??�?? */
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.scale:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

/* ???��?? ????�???��?? */

::selection {
  background: transparent;
}
/* CLOUDS */
body:before {
  /*content: "";*/
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 0;
  height: 0;
  margin: auto;
  border-radius: 100%;
  background: transparent;
  display: block;
  box-shadow: 0 0 150px 100px rgba(255, 255, 255, 0.6),
    200px 0 200px 150px rgba(255, 255, 255, 0.6),
    -250px 0 300px 150px rgba(255, 255, 255, 0.6),
    550px 0 300px 200px rgba(255, 255, 255, 0.6),
    -550px 0 300px 200px rgba(255, 255, 255, 0.6);
}
/* JUMP */
h1 {
  cursor: default;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  margin: auto;
  display: block;
  text-align: center;
}

h1 span {
  position: relative;
  top: 1px;
  display: inline-block;
  -webkit-animation: bounce 1.6s ease infinite alternate;
  /* font-size: 20px;
  color: #fff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
    0 2px 0 #ccc, 0 6px 0 transparent, 0 3px 0 transparent, 0 4px 0 transparent,
    0 5px 0 transparent, 0 5px 5px rgba(0, 0, 0, 0.4); */
}

h1 span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
}
h1 span:nth-child(3) {
  -webkit-animation-delay: 0.2s;
}
h1 span:nth-child(4) {
  -webkit-animation-delay: 0.3s;
}
h1 span:nth-child(5) {
  -webkit-animation-delay: 0.4s;
}
h1 span:nth-child(6) {
  -webkit-animation-delay: 0.5s;
}
h1 span:nth-child(7) {
  -webkit-animation-delay: 0.6s;
}
h1 span:nth-child(8) {
  -webkit-animation-delay: 0.2s;
}
h1 span:nth-child(9) {
  -webkit-animation-delay: 0.3s;
}
h1 span:nth-child(10) {
  -webkit-animation-delay: 0.4s;
}
h1 span:nth-child(11) {
  -webkit-animation-delay: 0.5s;
}
h1 span:nth-child(12) {
  -webkit-animation-delay: 0.6s;
}
h1 span:nth-child(13) {
  -webkit-animation-delay: 0.7s;
}
h1 span:nth-child(14) {
  -webkit-animation-delay: 0.8s;
}

/* ANIMATION */
@-webkit-keyframes bounce {
  100% {
    top: -3px;
    /* text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
      0 5px 0 #ccc, 0 6px 0 #ccc, 0 7px 0 #ccc, 0 8px 0 #ccc, 0 9px 0 #ccc,
      0 50px 25px rgba(0, 0, 0, 0.2); */
  }
}

.text-eff {
  background-image: -webkit-linear-gradient(
    45deg,
    red,
    orange,
    yellow,
    green,
    blue,
    indigo,
    violet,
    red
  ); /*그�?��???��?? 배경?? ?��?��?��?��??모�???��? 모�???��??*/
  background-position: 0 0;
  -webkit-background-clip: text; /*배경?? ???��?? �???��? ???��???*/
  background-size: 6% 30px; /*�??�?? 100% ?��? 30px ?�기*/
  background-repeat: repeat; /*�??복�????*/

  -webkit-animation: stripes 3s linear infinite; /*stripes?��?? ????�???��???? 3�??�?? linear infinite??*/
  animation: stripes 3s linear infinite;

  -webkit-text-fill-color: transparent; /*?? 배경?? �??�??�???? = background�?? 보�?��?! ???��?�면 배경?? �???��?*/
}

/*그리�?? ?��?????? ?��??!!??*/

@-webkit-keyframes stripes {
  100% {
    background-position: 0 -30px;
  } /*y�???��? -30px ?��?????? ?��??????*/
}
@keyframes stripes {
  100% {
    background-position: 0 -30px;
  }
}

/* ???��?��????�???��?? css ?�기�??�?? */

/* IE10+ */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .SMmoney_chargebutton {
    color: #ffffff;
    background-color: #c10000;
    width: 80px;
    height: 30px;
    line-height: 10px;
    padding: 0;
    margin: 25px 0 0 15px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

/* Chrome ?��??? �???��?????? ;; */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
}

/* ?��?  */
@supports (-ms-ime-align: auto) {
}

.game_banner_ad_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}

.game_banner_ad {
  width: 1000px;
  height: 175px;
  position: relative;
}

.game_ad_video {
  text-align: center;
  display: flex;
  /* ?��?��?? �???????? ??기�???? flex ?��?? */
  align-items: center;
  /* ?????? 기�? �???????? */
  justify-content: center;
  /* �???? 기�? �???????? */
}

a.AddEffect:hover {
    color: #15a3e1;
}
