
.my_page_contents_box {
    position: relative;
    display: block;
    width: 998px;
    height: auto;
    background-color: #ffffff;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-top: 2px solid #4e4e4e;
}

.my_page_contents {
    display: block;
    width: 100%;
    height: 76px;
    font-size: 16px;
    border-bottom: 1px solid #dbdbdb;
    line-height: 74px;
}

.my_contents_inner_left_box {
    position: relative;
    display: inline-block;
    width: 335px;
    height: 100%;
    font-weight: bold;
    background-color: #f9f9f9;
    border-right: 1px solid #efefef;
    text-align: center;
}

.my_contents_inner_right_box {
    display: inline-block;
    width: 281px;
    height: 100%;
    padding-left: 30px;
}

.my_password_box {
    position: absolute;
    display: inline-block;
    width: 280px;
    height: 35px;
    background-color: #f3f3f3;
    margin-left: 30px;
    margin-top: 20px;
}

.my_password_input_box {
    position: absolute;
    display: inline-block;
    width: 256px;
    height: 37px;
    font-size: 14px;
    padding: 8px 0 0 12px;
    z-index: 1;
}

.my_password_input_box input {
    color: #4e4e4e;
    width: 100%;
}

.my_password_explanation{
    position: absolute;
    display: inline-block;
    width: 250px;
    height: 30px;
    font-size: 12px;
    color: #7b7b7b;
    line-height: 1.5;
    padding-left: 320px;
    margin-top: 20px;
}

.my_section_tit_box_inner{
    position: relative;
    width: 1000px;
    height: 37px;
    top: 18px;
    left: 31px;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    text-align: left;
}
.my_quit_title_box {
    position: relative;
    display: block;
    width: 100%;
    height: 81px;
    font-size: 20px;
    font-weight: bold;
    background-color: #ffffff;
    border-bottom: 1px solid #efefef;
}

.my_quit_title {
    width: 950px;
    height: 56px;
    padding: 25px 0 0 30px;
}

.my_quit_ok_title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 40px;
    padding-top: 13px;
}

.my_quit_ok_contents {
    display: block;
    width: 100%;
    height: 130px;
    font-weight: normal;
    font-size: 14px;
    color: #7b7b7b;
    line-height: 1.8;
    padding-top: 100px;
    text-align: center;
}

.my_quit_ok {
    display: block;
    width: 100%;
    height: 130px;
    font-weight: normal;
    font-size: 14px;
    color: #7b7b7b;
    line-height: 1.8;
    padding-top: 100px;
}


.my_quit_box {
    width: 998px;
    height: 234px;
    border: 1px solid #dbdbdb;
    border-top: 2px solid #4e4e4e;
    background-color: #f9f9f9;
}

.my_quit_contents {
    display: block;
    width: 950px;
    height: 510px;
    font-weight: normal;
    font-size: 14px;
    padding: 28px 0 0 30px;
    line-height: 1.4;
}

.my_title_interval {
    width: auto;
    height: auto;
    font-weight: bold;
    color: #4e4e4e;
    margin-bottom: 16px;
}

.my_contents_interval{
    width: auto;
    height: 5px;
}

.my_quit_contents_box {
    display: block;
    width: 700px;
    height: 215px;
    font-size: 16px;
    background-color: #ffffff;
    border-bottom: 1px solid #e7e7e7;
    margin: 0 auto;
    text-align: center;
}

.my_withdraw_check_img {
    position: absolute;
    display: inline-block;
    width: 22px;
    height: 22px;
    margin: 2px 0 0 -30px;
}

.my_withdraw_check_img img {
    cursor: pointer;
}

.my_wallet_popup {
    display: block;
    position: fixed;
    z-index: 2000;
    padding-top: 15%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
}

.my_wallet_popup_content {
    position: relative;
    z-index: 3000;
    justify-content: center;
    margin: auto;
    width: 319px;
    height: 248px;
    border-radius: 20px;

    border: 4px solid #004279;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.my_wallet_popup_txt {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-top: 5px;
    line-height: 2;
}

.my_ok_no_box_button {
    display: block;
    width: 100%;
    height: 42px;
    padding-top: 20px;
    text-align: center;
}

.password_show_icon {
    position: absolute;
    right: 2px;
    top: 40%;
    transform: translateY(-50%);
    border: none;
    cursor: pointer;

    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("../images/eye_open.svg");
}

.password_hide_icon {
    position: absolute;
    right: 2px;
    top: 40%;
    transform: translateY(-50%);
    border: none;
    cursor: pointer;

    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("../images/eye_close.svg");
}