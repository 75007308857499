
.in_game_shop_tab_content {
    grid-gap: 43px;
    display: grid;
    gap: 43px;
    grid-template-columns: repeat(6, 0fr);
    margin-top: 40px;
}

.in_game_buy_section {
    position: relative;
    min-height: 351px;
    background-color: #000000;
}

.ing_game_buy_contentsBox {
    width: 100%;
    height: 50px;
    font-weight: bold;
    border-bottom: 2px solid rgb(78, 78, 78);
}


.in_game_shop_tab_center_bar {
  position: absolute;
  display: block;
  width: 100%;
  height: 30.5px;
  border-bottom: 2px solid rgb(78, 78, 78);
}

.in_game_shop_section_2 {
    position: relative;
    min-height: 351px;
    height: auto;
    border: 1px solid #333;
    background-color: #333;
}

.in_game_buy_button {
    border: 1px solid #244CB3;
    background-color: #132554;
    padding: 5px 15px;
    border-radius: 5px;
    color: #FFFFFF;
}

.in_game_buy_button:hover{
    background-color: #244CB3;
    color: #FFFFFF;
}

.in_game_ok_no_button {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 42px;
    font-size: 16px;
    color: #FFFFFF;
    background-color: #244CB3;
    text-align: left;
    padding-left: 14px;
    padding-bottom: 2px;
    margin-right: 10px;
    border-radius: 5px;
}

.game_shop_count_number {
    position: absolute;
    display: inline-block;
    width: 18px;
    height: 37px;
    margin-top: 5px;
    margin-left: 40px;
    border: 0.5px solid #ededed;
}

.game_count_number_up_img {
    position: absolute;
    width: 18px;
    height: 18px;
}

.game_count_number_down_img {
    position: absolute;
    width: 18px;
    height: 18px;
    margin-top: 20px;
}

.game_buy_drop_box_select {
    display: inline-block;
    position: relative;
    width: 140px;
    height: 100%;
    font-size: 14px;
    border: none;
    z-index: 1;
    background: url(../images/ingame_select_icon.png) no-repeat 95% 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px 14px;
    color: #FFFFFF;
}

.game_buy_drop_box_select option {
    background-color: #333;
}

.game_shop_avatar_center {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    color: #FFFFFF;
}