@media only screen and (min-width: 3840px) {
    .TopGamesection_video3 {
        height: 26rem;
    }

    .TopGamesection3 {
        height: 26rem;
    }

    .game-download-btn {
        margin-top: 4.6rem;
        cursor: pointer;
        width: 500px;
        height: 130px;
    }

    .game-guide-btn {
        position: absolute;
        margin-top: 27rem;
        cursor: pointer;
        width: 180px;
        height: 70px;
    }



}


@media only screen and (max-width: 1600px) {
    .TopGamesection_video3 {
        height: 15rem;
    }

    .TopGamesection3 {
        height: 15rem;
    }

    .game-guide-btn {
        position: absolute;
        margin-top: 15.3rem;
        cursor: pointer;
        width: 110px;
        height: 38px;
    }

    .game-download-btn {
        margin-top: 2.6rem;
        cursor: pointer;
        width: 275px;
        height: 80px;
    }

}

/*@media only screen and (min-width: 1920px) and (max-width: 2550px){*/
/*    .sub-footer {*/
/*        height: 200px;*/
/*        background: #333333;*/
/*    }*/
/*}*/

@media only screen and (max-width: 1280px) {
    body {
        min-width: 1280px;
    }

    @media only screen and (orientation: landscape) {
        body {
            zoom: 80%;
        }
    }

    @media only screen and (orientation: portrait) {
        .GameCenterwarp {
            transform: scale(0.9);
            transform-origin: top;
            height: 855px;

        }

        .ShopGameCenterwarp {
            transform: scale(0.9);
            transform-origin: top;
            height: 1640px;
        }

        .game-footer {
            transform: scale(0.95);
        }

        .popup-container {
            transform: scale(0.9);
            transform-origin: top;
        }

        .message_popup_container_div {
            transform: scale(0.9);
            transform-origin: top;
        }

        .message-main-pop {
            margin-top: 9rem !important;
        }

        .ServiceGameCenterWarp {
            transform: scale(0.9);
            transform-origin: top;
            height: 650px;
        }

        .GameCenterwarp_inner {
            height: unset;
        }

        .swiper-slide video {
            height: 40rem;
            pointer-events: none;
        }

        .BoxClass {
            width: 220px;
            height: 220px;
        }

        .main_div_images img {
            width: 174px;
            height: 175px;
        }

        .main_div_images {
            top: 88px
        }


        @media only screen and (max-width: 1200px) {
            .main_div_carosel {
                margin-top: -4px;
            }

            .BoxClass {
                width: 30vw;
                height: 30vw
            }

            .main_div_images img {
                width: 22vw;
                height: 22vw;
                z-index: 2;
            }

            .main_div_images {
                top: 12vw
            }
        }

    }
}